/* form */
.leftInner {
    max-width: 730px;
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.leftLog {
    position: relative;
    z-index: 3;
}

.rightLog {
    background-color: #68DA62;
    position: relative;
    z-index: 2;
}

.logFig {
    position: relative;
    z-index: 9;
}

.chromeExtNotif {
    width: 175px;
    height: 175px;
    background: rgba(249, 229, 34, 0.66);
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -55px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.chromeExtNotif .add {
    width: 27px;
    height: 27px;
    background: #F94024 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 0px 8px #00000062;
    box-shadow: 0px 0px 8px #00000062;
    border-radius: 50%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
}

.chromeExtNotif .add svg {
    margin-left: 1px;
    margin-top: 1px;
}

.chromeExtNotif .outerAdd {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    border-radius: 50%;
    border: 10px solid rgba(65, 154, 244, 0.70);
    background: rgba(249, 229, 34, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-animation: grow 2s linear 2s infinite forwards;
    animation: grow 2s linear 2s infinite forwards;
}

@-webkit-keyframes grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    60% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    90% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }
}

.logFig svg .a {
    fill: #fff;
}

.logC {
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
}

.logC-1 {
    width: 115.5px;
    height: 231px;
    top: 136px;
    z-index: -1;
    border-radius: 0;
    border-bottom-left-radius: calc(231px * 2);
    border-top-left-radius: calc(231px * 2);
    right: 100%;
}

.logC-2 {
    width: 183.5px;
    height: 367px;
    top: 0;
    z-index: -1;
    border-radius: 0;
    border-bottom-right-radius: calc(183.5px * 2);
    border-top-right-radius: calc(183.5px * 2);
    left: 0;
}

.logC-3 {
    width: 102px;
    height: 102px;
    right: 157px;
    bottom: 122px;
}

.logC-4 {
    width: 320px;
    height: 320px;
    right: -90px;
    bottom: -60px;
}

.logC-5 {
    width: 90px;
    height: 180px;
    z-index: -1;
    border-radius: 0;
    border-bottom-left-radius: calc(90px * 2);
    border-top-left-radius: calc(90px * 2);
    right: 0;
    bottom: 189px;
    z-index: 2;
}

.headerLog {
    margin-bottom: auto;
    padding: 39px 0 0;
}

.footerLog {
    position: relative;
    left: auto;
    margin-bottom: 15px;
    padding: 0;
    margin-top: auto;
    padding-right: 70px;
}

/* RESPONSIVE */
@media (max-width: 1600px) {
    .leftLog {
        padding: 0 15px;
    }
}

@media (max-width: 1335px) {
    .headerLog {
        padding: 20px 0 0;
    }

    .logForm {
        width: 100%;
        max-width: 100%;
    }

    .footerLog {
        padding-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .logForm {
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
}

@media (max-width: 1199px) {
    .logFig {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
    }
}

@media (max-width: 991px) {

    .logC-1,
    .logC-2 {
        display: none;
    }

    .headerSection {
        margin-bottom: 20px;
    }

    .logForm {
        max-width: 100%;
    }

    .forgotPass p,
    .footer p {
        font-size: 13px;
    }

    .logC-4 {
        width: 230px;
        height: 230px;
    }

    .logC-3 {
        width: 72px;
        height: 72px;
        right: 107px;
        bottom: 92px;
    }

    .logC-5 {
        width: 70px;
        height: 140px;
        bottom: 129px;
    }
}

@media (max-width: 767px) {
    .headerLog {
        position: fixed;
        top: 0;
        left: 15px;
        right: 15px;
        margin: 0;
    }

    .logScreen {
        flex-direction: column;
        justify-content: center;
    }

    .leftLog,
    .rightLog {
        width: 100%;
    }

    .leftLog {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logFig {
        display: none;
    }

    .leftInner {
        display: block;
        margin: 0;
        max-width: 100%;
    }

    .headerLog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: #F8F8F8;
        z-index: 2;
    }

    .footerLog {
        position: fixed;
        width: auto;
        right: 0;
        left: 0;
        padding: 0 15px;
    }

    .logC {
        position: fixed;
    }

    .logForm {
        margin-top: auto;
    }
}

@media (max-height: 600px) {
    .headerLog {
        margin-bottom: 30px;
    }

    .headerSection {
        margin-bottom: 15px;
    }

    .form-group+.form-group {
        margin-top: 15px;
    }

    .logForm {
        margin-bottom: 30px;
    }

    .logFig {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
    }
}

@media (max-height: 600px) and (max-width: 767px) {
    .logScreen {
        padding-top: 95px;
    }

    .footerLog {
        position: static;
        margin-top: 60px;
        padding: 0;
    }

}

/* RESPONSIVE */