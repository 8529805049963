@import url(https://use.typekit.net/waw7odo.css);
@font-face {
  font-family: "NHaasGroteskTXPro-75Bd";
  src: url(/static/media/NHaasGroteskTXPro-75Bd.0fc6cfb3.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/NHaasGroteskTXPro-75Bd.281b03c3.woff)
      format("woff"),
    url(/static/media/NHaasGroteskTXPro-75Bd.d0ade47d.ttf)
      format("truetype"),
    url(/static/media/NHaasGroteskTXPro-75Bd.61bc7a0d.svg#NHaasGroteskTXPro-75Bd)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NHaasGroteskTXPro-55Rg";
  src: url(/static/media/NHaasGroteskTXPro-55Rg.5f45ec31.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/NHaasGroteskTXPro-55Rg.5fb21fbe.woff)
      format("woff"),
    url(/static/media/NHaasGroteskTXPro-55Rg.49bd6b24.ttf)
      format("truetype"),
    url(/static/media/NHaasGroteskTXPro-55Rg.94e690be.svg#NHaasGroteskTXPro-55Rg)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AcuminPro-Regular";
  src: url(/static/media/AcuminPro-Regular.c9fa2eb7.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/AcuminPro-Regular.bdb37477.otf) format("opentype"),
    url(/static/media/AcuminPro-Regular.24225ec6.woff) format("woff"),
    url(/static/media/AcuminPro-Regular.326df883.ttf) format("truetype"),
    url(/static/media/AcuminPro-Regular.61665589.svg#AcuminPro-Regular)
      format("svg");
  font-weight: normal;
  font-style: normal;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset !important;
}

* {
  box-sizing: border-box;
  text-decoration: none;
  font-style: normal;
  outline: none;
  box-shadow: none;
  -webkit-font-smoothing: antialiased;
}

/* clears the 'X' from Internet Explorer */

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

:before,
:after {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  font-family: "AcuminPro-Regular", sans-serif;
  font-weight: 400;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

.blueSpacedButton {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 10px 10px 10px;
}

/* Lists==================== */

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Links, inputs and Buttons==================== */

a {
  color: inherit;
}

a:hover {
  color: #ccc;
}

a,
.btn,
.button,
button,
input[type="button"],
input[type="button"],
input[type="submit"],
input[type="submit"],
select {
  outline: none;
  transition: all ease 0.4s;
  cursor: pointer;
  font-family: inherit;
}

input,
textarea,
select {
  min-height: 40px;
  font-size: 16px;
  padding: 3px 10px;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid #ccc;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select {
  -webkit-appearance: none;
}

input::-ms-clear,
input:-ms-clear {
  display: none;
}

textarea {
  /* resize: none; */
  padding: 5px 10px;
  font-family: Arial;
}

textarea.version-info {
  border: none;
  resize: none;
  min-height: 100px;
  min-width: 300px;
}

:disabled,
::disabled {
  cursor: not-allowed;
}

:readonly,
::readonly {
  cursor: default;
}

::-webkit-input-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}

:-ms-input-placeholder {
  font-size: inherit;
  color: #bbb9d1;
}

::placeholder {
  font-size: inherit;
  color: #bbb9d1;
}

/* Tables==================== */

table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

td {
  vertical-align: middle;
}

/* Headings==================== */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Other elements==================== */

img {
  max-height: 100%;
  max-width: 100%;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

p + p {
  margin-top: 15px;
}

br,
.clear {
  clear: both;
}

audio,
video {
  display: block;
  width: 100%;
}

/* API Body style from Bootstrap/index.css */

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

code,
kbd {
  padding: 2px 4px;
  font-size: 70%;
}

code.codeYellow {
  color: #f98b00;
}

code.apiNote {
  font-size: x-small;
}

mark {
  background: #ff0;
  color: #000;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.titleUrl {
  margin-left: 10px;
  letter-spacing: 1px;
  background: #f4f4f4;
  line-height: 24px;
  border: 1px solid #d4d4d4;
  color: #282828;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 8px;
  word-break: break-all;
  width: 100%;
  margin-bottom: 16px;
}

.apiBody .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

caption,
th {
  text-align: left;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}

.apiBody .table > tbody > tr > td,
.apiBody .table > tbody > tr > th,
.apiBody .table > tfoot > tr > td,
.apiBody .table > tfoot > tr > th,
.apiBody .table > thead > tr > td,
.apiBody .table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.dataArea {
  margin-top: 10px;
}
.dataArea pre {
  overflow: auto;
}

.apiBody .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.apiBody h4 {
  margin: 10px 0;
}

.apiBody .table {
  margin-bottom: 20px;
}

/* API Body style from Bootstrap/index.css */

/* title tooltip */

a[data-title],
.tooltip[data-title] {
  position: relative;
  display: inline-block;
}

a[data-title]:hover:after,
.tooltip[data-title]:hover:after {
  content: attr(data-title);
  position: absolute;
  padding: 3px 11px 5px;
  border: 1px solid #dddddd;
  background: #fff;
  font-size: 14px;
  border-radius: 3px;
  color: #808080;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  box-shadow: 0 3px 6px #00000029;
  pointer-events: none;
  z-index: 1;
}

a[data-title]:hover:before,
.tooltip[data-title]:hover:before {
  content: "";
  position: absolute;
  top: 100%;
  width: 15px;
  height: 15px;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  background: linear-gradient(45deg, #fff 50%, transparent 50%);
  transform: rotate(135deg);
  margin-top: 3px;
  z-index: 2;
  pointer-events: none;
}

/* title tooltip */

/****OPTIONAL**** Common classes one can use for different purposes **************/

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.flex-1 {
  flex: 1 1;
}

.flex-0 {
  flex: 0 1;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.mt-a {
  margin-top: auto;
}

.mb-a {
  margin-bottom: auto;
}

.container {
  /* max-width: 1140px; */
  max-width: 1495px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}

.navbar > .container {
  display: flex;
}

.navbar-container {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  padding-left: 78px;
}

.clearfix:after {
  clear: both;
}

.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.row {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
}

.row:after {
  clear: both;
  display: table;
}

.col-1 {
  flex: 1 1 100%;
  max-width: 8.333%;
}

.col-2 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 16.667%;
}

.col-3 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 25%;
}

.col-4 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 33.333%;
}

.col-5 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 41.667%;
}

.col-6 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 50%;
}

.col-7 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 58.333%;
}

.col-8 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 66.667%;
}

.col-9 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 75%;
}

.col-10 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 83.333%;
}

.col-11 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 91.667%;
}

.col-12 {
  padding: 0 15px;
  flex: 1 1 100%;
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.f-align-center {
  align-items: center;
}

.f-justify-center {
  justify-content: center;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.linkText {
  text-decoration: underline;
  color: #419af4;
}

.btn svg,
button svg {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
  text-align: center;
}

.btn-hollow {
  background-color: transparent;
  border: 1px solid transparent;
}

.btn-curved {
  border-radius: 30px;
}

.btn-medium {
  height: 40px;
  width: 100%;
  font-size: 16px;
}

.btn-large {
  height: 60px;
  width: 100%;
  font-size: 18px;
}

.btn-blue {
  background-color: #419af4;
  color: #fff;
}

.btn-green {
  background-color: #67d859;
  color: #fff;
}

.btn-red {
  background-color: #e02251;
  color: #fff;
}

.btn-hollow-blue {
  color: #419af4;
  border-color: #419af4;
}

.btn-hollow-green {
  color: #67d859;
  border-color: #67d859;
}

.btn-blue:hover,
.btn-green:hover,
.btn-red:hover {
  color: #fff;
  background-color: #333;
}

.btn-hollow-blue:hover {
  background-color: #419af4;
}

.btn-hollow-green:hover {
  background-color: #67d859;
}

.btn-hollow-blue:hover,
.btn-hollow-green:hover {
  color: #fff;
}

.createBtn {
  height: 50px;
  padding: 0 36px 2px;
  position: relative;
  overflow: hidden;
}

.createBtn:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  left: 0;
  transform: translateX(-70px) rotate(45deg);
  top: 0.2px;
  transition: all ease 0.4s;
  pointer-events: none;
}

.createBtn span {
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 100%;
  transform-origin: 50% 60%;
  transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.4s;
  -webkit-appearance: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  left: 40px;
  opacity: 0;
}

.createBtn span em {
  display: none;
}

.createBtn:hover:after {
  transform: translateX(-22px) rotate(45deg);
}

.createBtn:hover span {
  transform: rotate(90deg) translateY(27px);
  opacity: 1;
}

.createBtn:hover {
  background-color: #67d859;
}

.downloadBtn {
  width: auto;
  display: inline-flex;
}

.downloadBtn svg {
  pointer-events: none;
  transition: all ease 0.4s;
  transform-origin: 100% 100%;
}

.downloadBtn:hover svg {
  transform: rotate(12deg);
}

.downloadBtn:hover .a {
  fill: #67d859;
}

.table-gen td .downloadBtn {
  margin-left: 5px;
}

.btnOptions {
  background: none;
  padding: 0;
  border: none;
  font-size: 0;
}

.btnOptions svg {
  pointer-events: none;
}

.menuDots .a {
  fill: #c4c4c4;
  fill-rule: evenodd;
}

.loader-gen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loader-gen svg {
  margin-right: 18px;
  transform: scale(2);
  display: inline-block;
}

.loader-gen span {
  font-size: 22px;
  color: #c4c4c4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loader-ico .a {
  fill: #f9e522;
  animation: loader-ico-a 2s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite
    forwards;
}

.loader-ico .b {
  fill: #f94024;
  animation: loader-ico-b 2s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite
    forwards;
}

.loader-ico .c {
  fill: #419af4;
  animation: loader-ico-c 2s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite
    forwards;
}

.loader-ico .d {
  fill: #67d859;
  animation: loader-ico-d 2s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite
    forwards;
}

@keyframes loader-ico-a {
  24% {
    fill: #f9e522;
  }
  25% {
    fill: #419af4;
  }
  49% {
    fill: #419af4;
  }
  50% {
    fill: #f94024;
  }
  74% {
    fill: #f94024;
  }
  75% {
    fill: #67d859;
  }
  99% {
    fill: #67d859;
  }
  100% {
    fill: #f9e522;
  }
}

@keyframes loader-ico-b {
  24% {
    fill: #f94024;
  }
  25% {
    fill: #67d859;
  }
  49% {
    fill: #67d859;
  }
  50% {
    fill: #f9e522;
  }
  74% {
    fill: #f9e522;
  }
  75% {
    fill: #419af4;
  }
  99% {
    fill: #419af4;
  }
  100% {
    fill: #f94024;
  }
}

@keyframes loader-ico-c {
  24% {
    fill: #419af4;
  }
  25% {
    fill: #f94024;
  }
  49% {
    fill: #f94024;
  }
  50% {
    fill: #67d859;
  }
  74% {
    fill: #67d859;
  }
  75% {
    fill: #f9e522;
  }
  99% {
    fill: #f9e522;
  }
  100% {
    fill: #419af4;
  }
}

@keyframes loader-ico-d {
  24% {
    fill: #67d859;
  }
  25% {
    fill: #f9e522;
  }
  49% {
    fill: #f9e522;
  }
  50% {
    fill: #419af4;
  }
  74% {
    fill: #419af4;
  }
  75% {
    fill: #f94024;
  }
  99% {
    fill: #f94024;
  }
  100% {
    fill: #67d859;
  }
}

.blocked .a {
  fill: #c4c4c4;
}

/*white blocks */

.boxed {
  padding: 15px;
  background: #ffffff;
  box-shadow: 0 0 60px #00000003;
  border-radius: 10px;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
}

.boxed:hover {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.07);
}

/*white blocks */

/* table menu */

.menuTrigger .a {
  opacity: 1;
}

.menuTrigger svg {
  pointer-events: none;
}

.menuTrigger .a path {
  opacity: 0.3;
  transition: 0.2s;
}

.menuTrigger .a path:nth-child(2) {
  transition-delay: 0.1s;
}

.menuTrigger .a path:nth-child(1) {
  transition-delay: 0.3s;
}

.menuTrigger .a path:nth-child(3) {
  transition-delay: 0.5s;
}

.btnOptions:hover .a path {
  opacity: 0.8;
}

.menu-options {
  position: relative;
  font-size: 0;
}

.menu-options.activeIn .menu-gen {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.menu-options .btn:not(.manageExtension) {
  justify-content: left;
  padding: 10px 8px;
  color: #808080;
  font-size: 15px;
}

.menu-options .btn {
  display: flex;
}

.menu-gen .btn {
  width: 100%;
}

.menu-options .btn:not(.manageExtension):hover {
  color: #67d859;
}

.menu-options .btn:not(.manageExtension):hover .a {
  fill: #67d859;
}

.menu-options .btn.suspendBtn:hover {
  color: #f94024;
}

.menu-options .btn.suspendBtn:hover .a {
  fill: #f94024;
}

.menu-options .btn + .btn {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 10px;
}

.menu-options .btn figure {
  margin-right: 18px;
  font-size: 0;
  width: 18px;
  text-align: center;
}

.menu-gen {
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 0 20px #00000010;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s;
  position: absolute;
  top: 100%;
  min-width: 190px;
  left: 0;
  z-index: 2;
}

.white-label-modal-form {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 607px;
  z-index: 2;
  box-shadow: 0 0 30px #0000001a;
  border-radius: 5px;
  border: 1px solid #ededed;
  overflow: hidden;
}

input.input-disabled {
  background-color: #aeadad !important;
  cursor: not-allowed;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

.modal-gen-in-big {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
  /* width: 100%; */
  z-index: 2;
  box-shadow: 0 0 30px #0000001a;
  border-radius: 5px;
  border: 1px solid #ededed;
  overflow: hidden;
  width: 55% !important;
  max-width: 90 !important;
}

.form-control.extensionSelect.planMultiSelect:after {
  opacity: 0;
}

.wlDescriptionDiv {
  overflow-y: scroll;
  vertical-align: middle;
  height: 90px;
}

.wlDescriptionDiv:hover {
  height: 200px;
  transition: height 0.3s ease-out 100ms;
}

@media (max-width: 1800px) {
  .menu-gen {
    left: auto;
    right: 10px;
  }
}

/* table menu */

/* background colors */

.redBg {
  background-color: #f94024;
}

.blueBg {
  background-color: #419af4;
}

.yellowBg {
  background-color: #f9e522;
}

.inlineFig {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}

.add .a {
  fill: #fff;
  font-size: 16px;
  font-family: "NHaasGroteskTXPro-75Bd";
  font-weight: 700;
}

.download .a {
  fill: #419af4;
}

.download .a:first-of-type {
  transform: translateY(0);
}

.doneDownload .a {
  fill: #67d859;
}

.doneDownload .a:first-of-type {
  animation: download 2s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1 forwards;
}

@keyframes download {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  5% {
    transform: translateY(0);
    opacity: 1;
  }
  60% {
    transform: translateY(19px);
    opacity: 1;
  }
  61% {
    transform: translateY(19px);
    opacity: 0;
  }
  70% {
    transform: translateY(-17px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Statistics */

.stat-up {
  color: #67d859;
}

.stat-down {
  color: #f94024;
}

.stat-up .a {
  fill: #67d859;
}

.stat-down .a {
  fill: #f94024;
}

/* tab */

.tabHeader {
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  align-items: stretch;
}

.tabHeader ul {
  display: flex;
}

.tabHeader li {
  border: 1px solid transparent;
  border-bottom: none;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}

.tabHeader li > .tabLink {
  cursor: pointer;
  padding: 21px 25px;
  font-size: 15px;
  color: #000000;
  display: block;
}

.tabHeader li.active {
  border-color: #dddddd;
}

.tabHeader li.active,
.tabHeader li.active .tabLink,
.tabHeader li > .tabLink.active {
  background-color: #fff;
  color: #808080;
}

.tabBody,
.tableDesign {
  padding: 0 21px;
  background: #ffffff;
  border-radius: 0 0 6px 6px;
  border: 1px solid #dddddd;
}

.tableDesign {
  border-radius: 6px;
}

.tab-loader {
  height: 62px;
  position: relative;
}

.apiBody {
  padding: 30px 0;
}

.tab-loader .loader-gen {
  position: absolute;
  z-index: 0;
}

.tab-loader svg {
  transform: scale(1);
  margin-right: 9px;
}

.tab-loader span {
  font-size: 15px;
}

.searchTab {
  order: 2;
  margin-left: auto;
  flex: 1 1;
  display: flex;
  align-items: center;
  /* 09-09-2020 */
  justify-content: flex-end;
  /* 01-11-2021 */
  padding-bottom: 8px;
}

.searchTab .form-control {
  height: calc(100% - 10px);
  margin-top: 5px;
  border: none !important;
}

/* 09-09-2020
.searchTab .search-gen {
  height: 100%;
  width: 100%;
  max-width: 537px;
  margin-left: auto;
} */

/*09-09-2020*/

.searchTab .search-gen {
  height: 100%;
  width: 100%;
  max-width: 250px;
  margin-left: 15px;
}

.searchTab .btn {
  height: 40px;
  padding: 0 27px;
  margin-left: 23px;
  white-space: nowrap;
}

.tabOpt {
  width: 100%;
  max-width: 200px;
}

.tabOpt .form_inner .form-control {
  border-color: #dddddd;
  position: relative;
}

@media (min-width: 768px) {
  .tabOpt .form_inner .form-control {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    bottom: -1px;
  }
}

/* tab */

/* search */

.search-gen {
  position: relative;
}

.search-gen .form-control {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #b7b7b7;
  padding-left: 48px;
  background-color: #fff;
}

.search-gen .bgForm {
  right: auto;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.search-gen .bgFormL {
  width: 65px;
  left: 340px !important;
}

.search-gen .bgForm .a {
  opacity: 1;
}

/* search */

/* table general */

.table-gen {
  table-layout: auto;
}
.fixed-table-layout {
  table-layout: fixed !important;
}

.table-gen tr:hover td {
  background-color: rgb(241, 241, 241);
}

.table-gen td,
.table-gen th {
  padding: 20px 8px 35px;
  text-align: left;
  min-height: 62px;
  border-bottom: 1px solid #dddddd;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}

.table-gen tbody tr:last-child td,
.table-gen tbody tr:last-child th {
  border: none;
}

.table-gen th {
  font-size: 15px;
  color: #808080;
  font-weight: normal;
}

.table-gen td {
  color: #808080;
  font-size: 15px;
  /* text-decoration: underline; */
}

.table-gen .table-name {
  color: #000000;
  text-decoration: none;
  font-size: 18px;
  overflow-wrap: break-word;
}

.table-gen .table-date {
  color: #808080;
  text-decoration: none;
}

.tabDashboard .table tr:nth-child(even) .downloadBtn svg {
  transform-origin: 0 100%;
}

.tabDashboard .table tr:nth-child(even) .downloadBtn:hover svg {
  transform: rotate(-12deg);
}

.table-gen-inLogo {
  font-size: 0;
  width: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 24px;
}

@media (max-width: 1028px) {
  .table-gen td,
  .table-gen th,
  .table-gen .table-name {
    font-size: 13px;
    padding: 18px 5px;
  }
  .table-gen-inLogo {
    margin-right: 10px;
  }
}

/* table general */

/* OR divider */

.orDivider {
  font-size: 15px;
  color: #808080;
  position: relative;
  display: inline-flex;
  width: 54px;
  align-items: center;
  justify-content: center;
}

.orDivider:after,
.orDivider:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  background-color: #808080;
  height: 1px;
  width: 5px;
}

.orDivider:before {
  left: 0;
}

.orDivider:after {
  right: 0;
}

/* OR divider */

/* input type file */

.dropFile {
  min-width: 168px;
  max-width: calc(50% - 20px);
  font-size: 15px;
  color: #c4c4c4;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all ease 0.2s;
  position: relative;
}

.dropFile input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: none;
  /* cursor: pointer; */
}

.extensionName {
  cursor: pointer;
}

.inputFile {
  position: relative;
  margin-left: 16px;
  cursor: pointer;
}

.inputFile input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.inputFile span {
  background: #67d859;
  color: #fff;
  font-weight: 400;
  min-width: 130px;
  padding: 0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding-bottom: 2px;
  pointer-events: none;
  transition: all ease 0.2s;
}

.inputFile:hover span {
  background-color: #333;
}

/* input type file */

/* table responsive */

.table-responsive table,
.table-responsive .table {
  width: 100%;
}

@media (max-width: 991px) {
  /* .table-responsive {
        overflow: auto;
        padding-bottom: 8px;
    } */
}

/* table responsive */

/* common modal  */

.cross_w .a {
  fill: #fff;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
}

.modal-gen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.75);
  /* HOTFIX FOR OCCASIONAL BLURRING OF MODAL */
  transform: translate3d(0, 0, 0) scale(1.01);
  -webkit-font-smoothing: subpixel-antialiased;
}

.modal-gen-in {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 607px;
  z-index: 2;
  box-shadow: 0 0 30px #0000001a;
  border-radius: 5px;
  border: 1px solid #ededed;
  overflow: hidden;
}

.modal-gen-in-not-model {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 30px 0 30px;
  z-index: 2;
  box-shadow: 0 0 30px #0000001a;
  border-radius: 5px;
  border: 1px solid #ededed;
  overflow: hidden;
}

.modal-headerImage {
  margin-right: 20px;
  font-size: 0;
}

.modal-headerImage:empty {
  margin-right: 0;
}

.modal-gen-body .form-control {
  height: 50px;
}

.modal-gen-header {
  background: #67d859;
  position: relative;
  padding: 18px 30px;
}

.modal-gen-header-blue {
  background: #419af4 !important;
}

.white-label-modal-header {
  background: #3658c7;
  position: relative;
  padding: 18px 30px;
}

.closeModal,
.closeWhiteLabelModal {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  line-height: 100%;
  font-size: 0;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 17px;
  top: 17px;
}

.modal-gen-body .form-group label {
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 20px;
}

button[type="submit"].btn-blue {
  margin-top: 20px;
}

.modal-headerTxt h3 {
  color: #ffffff;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 100%;
  text-transform: capitalize;
}

.modal-headerTxt p {
  font-size: 16px;
  color: #ffffff;
}

.modal-gen-body {
  max-height: 581px;
  overflow: auto;
  padding: 14px 30px 38px;
  background-color: #fff;
}

.manageexttassignment.modal-gen-body {
  padding: 14px 20px 38px;
}

.manageexttassignment .balcInput input.form-control {
  width: 80px;
}

.manageexttassignment.modal-gen-body .form-control {
  height: auto;
  font-size: 14px;
  color: #666;
}

.addNames h6 {
  font-weight: normal;
  font-size: 20px;
  color: #808080;
  margin-bottom: 10px;
}

.addedNames {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  margin-bottom: 17px;
}

.addedNames li {
  padding: 0 10px 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #808080;
  background: #dddddd;
  height: 30px;
  border-radius: 50px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.userList {
  margin: 20px 0;
  max-height: 228px;
  overflow: auto;
}

.userList-big {
  margin: 20px 0;
  max-height: 500px !important;
  overflow: auto;
}

.userList ul {
  padding: 0 8px;
}
.userList-big ul {
  padding: 0 8px;
}

.manageUserBody .userList > ul {
  padding: 0;
}

.manageUserBody .userList-big > ul {
  padding: 0;
}

.uploadcsv .userList > ul {
  padding: 0;
}

.uploadcsv .userList > ul > li {
  padding-left: 5px;
}

.uploadcsv .balcInput input.form-control {
  width: 80px;
}

.userList li {
  color: #808080;
  /* font-size: 15px; */
  font-size: 14px;
  /* height: 50px; */
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  padding-right: 50px;
  position: relative;
  border-radius: 3px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
  overflow: hidden;
  flex-wrap: wrap;
}

.userList-big li {
  color: #808080;
  /* font-size: 15px; */
  font-size: 14px;
  /* height: 50px; */
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  padding-right: 50px;
  position: relative;
  border-radius: 3px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
  overflow: hidden;
  flex-wrap: wrap;
}

.userList li span {
  line-height: 100%;
}

.userList li + li {
  margin-top: 5px;
}

.userList li.selected {
  background-color: #67d859;
  color: #fff;
}

.userList li:hover {
  background-color: #278be9;
  color: #fff;
}

.userList-big li span {
  line-height: 100%;
}

.userList-big li + li {
  margin-top: 5px;
}

.userList-big li.selected {
  background-color: #67d859;
  color: #fff;
}

.userList-big li:hover {
  background-color: #278be9;
  color: #fff;
}

.userList li button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
}

.userList-big li button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
}

.userList li button .a {
  fill: #000;
}

.userList li button:hover {
  background-color: #419af4;
}

.userList li.selected button:hover {
  background-color: tomato;
}

.userList li button:hover .a {
  fill: #fff;
}

.userList li svg {
  transform: rotate(45deg) scale(0.8);
  transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.4s;
}

.userList li.selected svg {
  transform: rotate(0) scale(0.8);
}

.userList li.selected button .a {
  fill: #fff;
}

.userList-big li button .a {
  fill: #000;
}

.userList-big li button:hover {
  background-color: #419af4;
}

.userList-big li.selected button:hover {
  background-color: tomato;
}

.userList-big li button:hover .a {
  fill: #fff;
}

.userList-big li svg {
  transform: rotate(45deg) scale(0.8);
  transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.4s;
}

.userList-big li.selected svg {
  transform: rotate(0) scale(0.8);
}

.userList-big li.selected button .a {
  fill: #fff;
}

.noUser {
  margin-bottom: 20px;
  font-size: 0;
}

/* common modal  */

/* custom centered section with width of 710px */

.modWidth {
  margin: 0 auto;
  width: 100%;
  max-width: 710px;
  padding: 0 0 100px;
}

.small-modWidth {
  max-width: 500px;
}

/* custom centered section with width of 710px */

/* custom codes for full platform */

.bodyLoggedIn {
  padding-top: 70px;
}

.bodyLoggedIn .container {
  max-width: 1426px;
}

.logScreen {
  min-height: 100vh;
  overflow: hidden;
}

.header {
  position: relative;
  z-index: 8;
  padding: 29px 0;
}

.headerSection {
  margin-bottom: 40px;
}

.headerSection h2 {
  font-weight: normal;
  position: relative;
  display: inline-block;
  font-size: 32px;
}

.mainInner {
  padding-bottom: 60px;
}

.mainInner .headerSection h2 {
  font-size: 30px;
}

.headerSection .inlineFig {
  margin-left: 10px;
}

.headerSection p {
  color: #808080;
  font-size: 15px;
  margin-top: 9px;
}

.logo {
  display: inline-block;
  max-width: 97px;
}

.logo .a {
  /* had to reduce font size of logo from 24 to 22 */
  font-size: 22px;
  font-family: "NHaasGroteskTXPro-55Rg";
}

.logo .b {
  fill: #f94024;
}

.logo .c {
  fill: #67d859;
}

.logo .d {
  fill: #419af4;
}

.logo .e {
  fill: #f9e522;
}

.logo .f {
  fill: #fff;
}

.logout .a {
  fill: #c4c4c4;
}

.logoIco .a {
  fill: #c4c4c4;
}

.logoIco .b {
  fill: #fff;
}

.bgMain {
  background-color: #f8f8f8;
}

.add .a {
  fill: #fff;
  font-size: 16px;
  font-family: AcuminPro-Bold, "AcuminPro-Regular";
  font-weight: 700;
}

.trash .a {
  fill: #c4c4c4;
}

.cross .a {
  fill: #fff;
}

.search-ico .a,
.profile .a {
  fill: #ddd;
}

.main {
  min-height: 100%;
  min-height: 100vh;
}

/* navbar */

.navbar-header {
  display: flex;
  align-items: center;
}

.headerOptions {
  font-size: 0;
}

.headerMenu {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: stretch;
}

.headerMenu a:not(.btn-hollow) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #419af4;
  position: relative;
}

.headerMenu a:not(.btn-hollow):after {
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  left: 0;
  border-bottom: 1px dashed #419af4;
  transition: all ease 0.4s;
}

.headerMenu a:not(.btn-hollow):hover:after {
  border-style: solid;
}

@media (min-width: 992px) {
  .headerMenu {
    margin-right: 35px;
  }
  .headerMenu a + a {
    margin-left: 30px;
  }
}

.userMenu {
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  flex: 0 0 50px;
}

.nav-menuTray {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  width: 79px;
  background-color: #fff;
  box-shadow: 0 0 20px #00000010;
  border-radius: 3px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -16px;
  white-space: nowrap;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
}

.nav-menuTray.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  width: 246px;
}

.userSnap {
  min-height: 82px;
  position: relative;
  padding: 15px;
  background: #67d859;
}

.nav-menuTray {
  white-space: normal;
}

.closeUserMenu {
  position: absolute;
  padding: 0;
  right: 12px;
  top: 12px;
}

.userSnap .welcomePanel {
  font-size: 22px;
  color: #fff;
  margin-left: 12px;
}

.nav-menuTray ul {
  padding: 23px;
}

.nav-menuTray ul li {
  position: relative;
}

.nav-menuTray ul li:after {
  position: absolute;
  content: "";
  bottom: 50%;
  left: 0;
  height: 1px;
  width: 100%;
  background: #67d859;
  opacity: 0;
  pointer-events: none;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
}

.nav-menuTray ul li:last-child:after {
  background: #db5b47;
}

.nav-menuTray ul li:hover:after {
  bottom: 0;
  opacity: 1;
}

.nav-menuTray ul li + li {
  margin-top: 10px;
}

.nav-menuTray ul li:hover span:not(.logoutSpan),
.nav-menuTray ul li:hover a:not(.logoutSpan) {
  color: #67d859;
}

.nav-menuTray ul li:hover span:not(.logoutSpan) .a,
.nav-menuTray ul li:hover a:not(.logoutSpan) .a {
  fill: #67d859;
  opacity: 1;
}

.nav-menuTray ul li:hover .logoutSpan {
  color: #db5b47;
}

.nav-menuTray ul li:hover .logoutSpan .a {
  fill: #db5b47;
  opacity: 1;
}

.nav-menuTray ul span,
.nav-menuTray ul a {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #808080;
  padding: 7px 0;
  font-size: 15px;
  transition: all ease 0.4s;
  transition-delay: 0.2s;
  opacity: 0;
  white-space: nowrap;
}

.nav-menuTray.active ul a,
.nav-menuTray.active ul span {
  transition-delay: 0.2s;
  transition: none;
  opacity: 1;
}

.nav-menuTray ul li figure {
  font-size: 0;
  margin-right: 15px;
}

.navbar .btn-medium {
  height: 50px;
  width: auto;
  padding: 0 35px;
}

.navbar .createBtn + .createBtn {
  margin-left: 15px;
}

/* navbar */

/* form kyubi */

input:-webkit-autofill {
  -webkit-text-fill-color: #808080;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #808080;
}

.error input:-webkit-autofill {
  -webkit-text-fill-color: #f94024;
}

.error input:-webkit-autofill:focus {
  -webkit-text-fill-color: #f94024;
}

.form-group + .form-group {
  margin-top: 45px;
}

.modal-gen-body .form-group + .form-group {
  margin-top: 30px;
}

.form-group + .form-group:last-child,
.form-group + .form-group:last-of-type {
  margin-top: 35px;
}

.form-group label {
  font-size: 15px;
  color: #808080;
  display: block;
  line-height: 31px;
}

.form_inner {
  position: relative;
}

.form_inner .form-control {
  position: relative;
  height: 100%;
  width: 100%;
  color: #808080;
  padding: 5px 10px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  height: 60px;
  border-radius: 3px;
  font-size: 15px;
  transition: all ease 0.4s;
}

.form_inner select.form-control{
  background-image: url(/static/media/down-dark.511ed015.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 40px;
}

.form_inner textarea.form-control {
  min-height: 137px;
}

.form_inner .form-bg {
  padding-right: 50px;
}

.form_inner .form-control:focus,
.modal-gen-body .form-control:focus,
.search-gen .form-control:focus {
  border-color: #419af4;
}

.form_inner .form-control:focus + .bgForm .a,
.modal-gen-body .form-control:focus + .bgForm .a,
.search-gen .form-control:focus + .bgForm .a {
  fill: #419af4;
  opacity: 1;
}

.bgForm {
  position: absolute;
  top: 20px;
  right: 18px;
  font-size: 0;
  pointer-events: none;
}

.bgForm svg .a {
  opacity: 0.297;
}

.error input.form-control {
  border-color: #f94024;
}

.error input.form-control,
.error_msg {
  color: #f94024;
}

.error .bgForm svg .a {
  fill: #f94024;
  opacity: 1;
}

.error_msg {
  font-size: 13px;
  position: absolute;
  top: 100%;
  right: 5px;
}

.forgotPass p {
  color: #808080;
  font-size: 15px;
  line-height: 31px;
}

.forgotPass p a {
  color: #419af4;
  text-decoration: underline;
}

.forgotPass p a:hover {
  color: #333;
}

.logForm {
  width: 100%;
  max-width: 500px;
}

.logForm .forgotPass {
  margin-top: 35px;
}

/* form kyubi */

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px;
  width: 100%;
  text-align: center;
}

.footerCommon {
  width: 100%;
  max-width: 696px;
  padding: 0 15px 15px;
  margin: 0 auto;
  /* margin-top: 60px; */
  /* position: relative; */
  /* left: auto;
    right: auto; */
  text-align: inherit;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.socFB .a {
  fill: #4363ba;
}

.socMSG .a {
  fill: #2472dc;
}

.footer p {
  color: #808080;
  font-size: 15px;
  line-height: 31px;
}

.footer p a {
  color: #419af4;
}

.footer p a:hover {
  color: #333;
}

.socialFt {
  font-size: 0;
}

.socialFt a {
  margin-left: 20px;
}

.socialFt a:hover svg .a {
  fill: #333;
}

.passwordLock .a {
  opacity: 0.297;
}

/* Dashboard */

.kyubiStatistics .col {
  display: flex;
}

.dashboardBlocks {
  /* height: 190px; */
  height: auto;
  padding: 44px 40px 34px;
  flex-direction: column;
  width: 100%;
}

.statNumbers {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.statNumbers + .statNumbers {
  border-top: 1px solid #efefef;
}

.statNumbers + .statNumbers span {
  color: #f94024;
}

.dashboardBlocks h5 {
  font-size: 15px;
  color: #c4c4c4;
  font-weight: 400;
  position: relative;
  padding-bottom: 11px;
}

.dashboardBlocks h5:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #c4c4c4;
  transition: width cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.4s;
}

.dashboardBlocks:hover h5:after {
  width: 100%;
}

.statNumbers h3 {
  /* font-size: 50px; */
  font-size: 36px;
  font-weight: 400;
}

/* .statNumbers h3 span */

.statNumbers span {
  display: inline-block;
  max-width: calc(100% - 55px);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #419af4;
}

.statNumbers h3 sup {
  font-size: 13px;
  position: relative;
  margin-left: 15px;
  display: inline-block;
}

.statNumbers sup svg {
  margin-right: 5px;
  display: inline-block;
  vertical-align: 2px;
}

.dashboardBlocks .linkText {
  position: absolute;
  bottom: 49px;
  right: 34px;
  font-size: 15px;
  line-height: 20px;
}

.kyubiStatistics {
  margin-bottom: 100px;
}

/* Modifications 06-15-2020 */

.extensionItems .ico_ext:first-child {
  margin-left: 0;
}

.extensionItems .ico_ext:last-child {
  margin-right: 0;
}

.ico_ext {
  display: inline-flex;
  vertical-align: middle;
  width: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: 26px; */
  margin: 0 5px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.suspended td {
  position: relative;
}

.suspended td:first-child:after {
  content: "-Suspended-";
  display: flex;
  align-items: center;
  color: #f94024;
  font-size: 15px;
}

suspended td:not(:last-child):after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgba(255, 255, 255, 1);
}

/* Modifications 06-15-2020 */

/* extension details page */

.extensionDetails {
  margin-bottom: 70px;
}

.extdetails {
  text-align: center;
}

.extInfo {
  font-size: 0;
}

.extImg {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin: 0 auto 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.extInfo h2 {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 20px;
}

.extInfo ul {
  display: inline-flex;
  align-items: center;
}

.extInfo li {
  text-align: left;
}

.extInfo li:first-child {
  margin-left: 0;
}

.extInfo li:last-child {
  margin-right: 0;
}

.extInfo li span {
  font-size: 12px;
  color: #c4c4c4;
  text-transform: uppercase;
  display: block;
}

.extInfo li h6 {
  font-size: 20px;
  color: #808080;
  font-weight: normal;
  margin-top: 6px;
}

.extInfo li + li {
  margin: 0 35px;
}

.btnApi {
  border: 1px solid #419af4;
  border-radius: 5px;
  background: #ffffff;
  height: 40px;
  font-size: 14px;
  color: #419af4;
  padding: 0 32px;
}

.btnApi:hover {
  background-color: #2472dc;
  color: #fff;
}

.paraExt {
  margin: 0 auto;
  margin-top: 35px;
  text-align: center;
  max-width: 816px;
}

.paraExt p {
  color: #808080;
  line-height: 28px;
  font-size: 15px;
  font-weight: normal;
  font-family: "AcuminPro-Regular";
}

/* extension details page */

.menu-gen .btn-upload .a:last-child {
  display: none;
}

.menu-gen .btn-upload .a:first-child {
  transform: translateY(19px) translateX(24px) rotate(-180deg);
  -webkit-transform: translateY(19px) translateX(24px) rotate(-180deg);
  -moz-transform: translateY(19px) translateX(24px) rotate(-180deg);
  -ms-transform: translateY(19px) translateX(24px) rotate(-180deg);
  -o-transform: translateY(19px) translateX(24px) rotate(-180deg);
}

.table-gen td.active {
  color: #67d859;
}

.table-gen td.inactive {
  color: #f94024;
}

.suspended td {
  position: relative;
}

.suspended td:first-child:after {
  content: "-Suspended-";
  display: flex;
  align-items: center;
  color: #f94024;
  font-size: 15px;
}

.suspended td:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgba(255, 255, 255, 1);
}

.customPointer a,
.customPointer span {
  cursor: pointer;
  color: #419af4;
}

.setBalance {
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  margin-left: auto;
  margin-right: 8px;
  height: calc(100% - 16px);
}

.setBalance select {
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: #fff;
  height: 22px;
  min-height: 1px;
  padding: 0 25px 0 8px;
  border-radius: 3px;
  color: #666;
  background-image: url(/static/media/down-dark.511ed015.svg);
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: right 6px top 9px;
  border: 1px solid #343a40;
}

.selected .setBalance select {
  border: none;
}

.balcInput,
.balcInput input.form-control {
  height: 100%;
  min-height: 100%;
}

.balcInput {
  margin-left: 8px;
}
.balcInput.unlimitedBalcInput{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.balcInput.unlimitedBalcInput .unlimited {
    font-size: 14px;
    width: 198px;
    display: flex;
    align-items: center;
}

.balcInput.unlimitedBalcInput .unlimited .box_1 .toogler{
  top: 0.2px;
}

.balcInput input[type="checkbox"] {
  min-height: auto;
}

.balcInput input.form-control {
  font-size: 13px;
  padding: 0 5px;
  width: 110px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #fff;
  text-align: center;
  color: #333;
}

.balcInput input.form-control:focus {
  border-color: #ff6347;
}

.setBalance > label {
  font-size: 14px;
  line-height: 100%;
  white-space: nowrap;
  padding-top: 6px;
}

.ul-super-user-li {
  display: inline;
  cursor: pointer;
}

/* 09-09-2020 */

.searchTab .form-control select {
  font-size: 13px;
  background: #fff;
  border: none;
  padding: 0 25px 0 15px;
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.03);
  color: #afafaf;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 100%;
}

.searchTab .btn[title] {
  font-size: 13px;
  width: auto;
  padding: 3px 20px 0;
  margin-left: 15px;
}

.extensionSelect {
  position: relative;
}

.extensionSelect:after {
  position: absolute;
  content: "";
  border: 5px solid transparent;
  border-top: 5px solid #333;
  right: 7px;
  top: 22px;
  transform: translateY(-50%);
}

.selectPlan_api {
  margin-top: 25px;
  color: #f98b00;
}

.selectPlan_api span,
.selectPlan_api select {
  color: #f98b00;
  font-size: 14px;
}

.selectPlan_api select.form-control {
  padding-left: 10px;
  height: 33px;
  min-width: 80px;
  width: auto;
  max-width: 120px;
  margin-left: 30px;
  border-color: #a9a8a8;
  font-size: 14px;
  min-height: 1px;
}

.manageUserBody .extensionSelect {
  margin-left: 0;
  border-bottom: 1px solid #dddddd;
  margin-top: 10px;
}

.custom_select,
.extensionSelect {
  border: 1px solid #dddddd;
  border-radius: 5px;
  border-bottom: none;
  background: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  margin-left: 20px;
  position: relative;
}

.custom_select select,
.extensionSelect select {
  border: none;
  height: 100%;
  padding: 0 25px 0 35px;
  background-image: url(/static/media/down-dark.511ed015.svg);
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.manageUserBody .extensionSelect select {
  background-image: none;
  width: 100%;
  padding-left: 15px;
}

.custom_select .icon {
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
}

.custom_select:hover .icon {
  fill: #6687f5;
}

.plan_row {
  display: flex;
  justify-content: space-between;
}

.plan_row .form-control {
  height: 42px;
  font-size: 14px;
}

.plan_row button {
  margin-left: 15px;
  width: 45px;
  border-radius: 5px;
  border: none;
  font-size: 0;
  background: #d67c7c;
}

.plan_row:hover button,
.plan_row button:hover {
  background-color: #d15151;
}

.plan_row button .cross {
  width: 12px;
}

.btnManage:hover svg,
.btnManage:hover svg * {
  fill: #67d859;
}

#whitelabel_modal_container .custom_select,
#modalWhitelabelContainer .custom_select {
  margin: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  height: auto;
  border: none;
}

#whitelabel_modal_container .custom_select select,
#modalWhitelabelContainer .custom_select select {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 3px 10px;
  padding-right: 30px;
  border: 1px solid #cccccc;
}

.headerMenu .blueSpacedButton {
  padding: 0 15px;
  margin: 0 15px 0 0;
}

.blueSpacedButton span {
  width: 30px;
  margin-right: 8px;
  vertical-align: middle;
  font-size: 0;
}

.whitelabelCreationModal .modal-gen-in {
  max-width: 767px;
}

.whitelabelCreationModal input[type="file"] {
  cursor: pointer;
}

.whitelabelCreationModal h5 {
  font-size: 15px;
  color: #808080;
  display: block;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 20px;
}

.assignBalance {
  order: 4;
  flex: 1 1 100%;
  width: 100%;
  padding: 0;
  margin: 10px 0 0 0;
  align-items: flex-start;
}

.manageexttassignment li {
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 0;
  display: flex;
}

.assignBalance .balcInput {
  width: auto;
  flex: 1 1 auto;
  margin: 0;
  padding-left: 10px;
}

.balcInput > label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 10px;
  margin-bottom: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.balcInput > label input {
  display: none;
}

.balcInput > label input + span {
  position: relative;
  display: flex;
  padding-left: 25px;
  font-size: 13px;
  align-items: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.balcInput > label:hover {
  background-color: #337fcc;
}

.balcInput > label {
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  height: 22px;
  border-radius: 30px;
  overflow: hidden;
  background: #419af4;
  padding-right: 8px;
  cursor: pointer;
}

.balcInput > label input + span:before {
  content: "";
  background: #fff;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  position: absolute;
}

.balcInput > label input:checked + span:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 6px;
  width: 5px;
  height: 9px;
  border: none;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  background: none;
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
}

.image-display {
  flex: 0 0 50%;
  max-width: 200px;
  border: 2px solid #b7b7b7;
  border-radius: 5px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.image-display img {
  max-height: 40px;
}

.loaderSet .image-display {
  margin: 8px 0 0 0;
  width: 100%;
  max-width: 100%;
}

/* .loaderSet .image-display * {
  height: 40px;
} */

/* 02-11-2021 */
.formInWhitelabel-step {
  display: none;
}
.formInWhitelabel-step.active {
  display: flex;
}

.drawWhitelabel .a {
  fill: #a4b2c1;
}

.drawWhitelabel .b {
  fill: #bcddff;
}

.drawWhitelabel .c {
  fill: #419af4;
}

.drawWhitelabel .d {
  fill: #2f2e41;
}

.drawWhitelabel .e {
  fill: #ccc;
}

.drawWhitelabel .f {
  fill: #e6e6e6;
}

.drawWhitelabel .g {
  fill: #f94024;
}

.formInWhitelabel .form-control input,
.formInWhitelabel .form-control select,
.formInWhitelabel .form-control textarea {
  width: 100%;
  border: none;
  height: 34px;
  min-height: 1px;
  font-size: 13px;
  padding-left: 10px;
}

.formInWhitelabel .form-control .textShort {
  min-height: 78px;
  height: auto;
}

.formInWhitelabel .form-control .textLong {
  min-height: 165px;
  height: auto;
}

.formInWhitelabel .form-control {
  padding: 0;
  height: inherit;
  border: none;
  position: relative;
  margin: 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.btnArrow .a {
  fill: none;
}

.btnArrow .b,
.btnArrow .c {
  stroke: none;
}

.btnArrow .c {
  fill: #fff;
}
.formInWhitelabel .col-6 {
  margin-bottom: 40px;
}
.formInWhitelabel .col-12 {
  margin-bottom: 28px;
}

.noteInForm {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  font-size: 11px;
  color: #000000;
}

.formInWhitelabel .form-row > label {
  font-size: 13px;
  color: #000000;
}

.formInWhitelabel .btn-medium {
  height: 34px;
  font-size: 13px;
}
.formInWhitelabel .btn-medium .btnArrow {
  margin-left: 9px;
}
.formWhitelabel {
  margin-top: 40px;
}
.formInWhitelabel {
  margin-top: 58px;
}
.formInWhitelabel span.asterix {
  color: red;
  font-size: 16px;
}
.whitelabelFormSteps li {
  display: inline-flex;
  align-items: center;
  color: #b7b7b7;
  font-size: 13px;
  cursor: pointer;
}

.whitelabelFormSteps li + li {
  margin-left: 55px;
}
.whitelabelFormSteps li span {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  border: 1px solid #b7b7b7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.whitelabelFormSteps li.active span {
  border-color: #419af4;
  background-color: #fff;
}

.whitelabelFormSteps li.complete span {
  border-color: #67d859;
  background-color: #67d859;
  font-size: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiIHZlcnNpb249IjEuMSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA1MTUuNTU2IDUxNS41NTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0ibTAgMjc0LjIyNiAxNzYuNTQ5IDE3Ni44ODYgMzM5LjAwNy0zMzguNjcyLTQ4LjY3LTQ3Ljk5Ny0yOTAuMzM3IDI5MC0xMjguNTUzLTEyOC41NTJ6IiBmaWxsPSIjZmZmZmZmIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBzdHlsZT0iIi8+PC9nPjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
}

.whitelabelFormSteps li.active {
  color: #419af4;
}
.whitelabelFormSteps li.complete {
  color: #67d859;
}
label {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.whitelabelStepTwo label {
  display: block;
  cursor: pointer;
}
.whitelabelStepTwo label + .form-control {
  margin-top: 15px;
}
.whitelabelStepTwo label input[type="checkbox"] {
  display: none;
}
.whitelabelStepTwo label input[type="checkbox"] + span {
  position: relative;
  display: block;
}
.whitelabelStepTwo label input[type="checkbox"] + span:before {
  content: "";
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  background-color: #fff;
  border: 1px solid #707070;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-bottom;
}
.whitelabelStepTwo label input[type="checkbox"]:checked + span:before {
  border-color: #ebebeb;
  background-color: #ebebeb;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiIHZlcnNpb249IjEuMSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIHg9IjAiIHk9IjAiIHZpZXdCb3g9IjAgMCA1MTUuNTU2IDUxNS41NTYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZD0ibTAgMjc0LjIyNiAxNzYuNTQ5IDE3Ni44ODYgMzM5LjAwNy0zMzguNjcyLTQ4LjY3LTQ3Ljk5Ny0yOTAuMzM3IDI5MC0xMjguNTUzLTEyOC41NTJ6IiBmaWxsPSIjQURBREFEIiBkYXRhLW9yaWdpbmFsPSIjQURBREFEIiBzdHlsZT0iIi8+PC9nPjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
}
.logoSettings {
  background-color: #edeff0;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
}
.logoHeaderLabel {
  height: 34px;
  border-radius: 4px;
  background: #9eaebf;
  font-size: 13px;
  color: #fff;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 30px;
  display: flex;
  align-items: center;
}
.logoSettingsBody {
  padding: 45px 28px 32px;
}
.logoSettingsFooter {
  padding: 24px 28px;
  background-color: #9eaebf;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.logoSettings .form-control {
  height: 59px;
  background: #fff;
  padding: 6px;
  align-items: center;
}
.logoSettings .form-control figure {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border: 1px solid #edeff0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
}
.logoSettings .form-control input {
  color: #9b9b9b;
  padding: 5px 0;
  cursor: pointer;
}
input[type="file"]::-webkit-file-upload-button {
  height: 24px;
  margin-right: 5px;
  background: #edeff0;
  border-radius: 3px;
  border: 1px solid #cecece;
  color: #b4b4b4;
}
.logoSettings figure img {
  max-width: 100%;
  max-height: 100%;
}
.logoSettings .form-row + .form-row {
  margin-top: 25px;
}
.logoSettingsFooter .form-row label {
  color: #fff;
}
.logoSettings label + .form-control {
  margin-top: 10px;
}
.tabHeader .selectTab li span {
  color: #000;
}
.tabHeader .selectTab li.active span {
  background: #f4f4f4;
  color: #808080;
}
.tabHeader .searchTab {
  padding: 10px 13px;
  margin: 0;
  background: #f4f4f4;
  border: 1px solid #dddddd;
  justify-content: flex-start;
  display: flex;
}
.tabHeader .selectTab {
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
}
.dataArea-content .tableDesign {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.tabHeader .searchTab .search-gen {
  max-width: 427px;
  order: 1;
  margin-left: 0;
  margin-right: auto;
}
.tabHeader .searchTab .extensionSelect + .search-gen {
  margin-right: 15px;
}
.tabHeader .searchTab .search-gen .form-control {
  font-size: 13px;
  margin-top: 0;
}
.tabHeader .searchTab .extensionSelect {
  order: 2;
  margin-left: 15px;
  margin-top: 0;
  height: auto;
  margin-left: 0;
  margin-right: auto;
}
.tabHeader .searchTab .extensionSelectPartner {
  order: 2;
  margin-left: 15px;
  margin-top: 0;
  height: auto;
  margin-left: 0;
  position: relative
}
.extensionSelectPartner:after {
    position: absolute;
    content: "";
    border: 5px solid transparent;
    border-top: 5px solid #333;
    right: 7px;
    top: 22px;
    transform: translateY(-50%);
}
.tabHeader .searchTab .btn.btn-hollow {
  order: 3;
}
.tabHeader .searchTab .btn {
  order: 4;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.tabHeader {
  flex-wrap: wrap;
}
.tabHeader .selectTab,
.tabHeader .searchTab {
  flex: 1 1 100%;
  width: 100%;
  justify-content: space-between;
}
.tabHeader .selectTab {
  padding-left: 15px;
}

.videoModal {
  font-size: 0;
}
.videoModal iframe {
  width: 100%;
  height: 350px;
  border-radius: 3px;
  border: none;
  box-shadow: none;
}
/* .videoWhiteLabel {
  display: none;
} */
.videoWhiteLabel .modal-gen-header {
  background: #fff;
}
.videoWhiteLabel .closeModal .a {
  fill: #333;
}
.videoWhiteLabel .closeModal {
  height: 100%;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
}
.textVideoModal {
  margin-top: 15px;
}
.textVideoModal p {
  font-size: 13px;
  line-height: 17px;
}
.skipVideo {
  margin-top: 15px;
}
.videoWhiteLabel .btn {
  width: auto;
  margin-left: auto;
  font-size: 12px;
  height: auto;
  border: none;
  padding: 7px 15px;
  box-shadow: none;
  background-color: #18a168;
}
.videoWhiteLabel .btn:hover {
  background-color: #333;
}
.textVideoModal h5 {
  font-size: 18px;
  margin-bottom: 10px;
}
.videoWhiteLabel .modal-gen-body {
  max-height: 600px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.videoWhiteLabel .modal-gen-in {
  max-width: 580px;
}

.tagsTd svg {
  fill: #fff;
}
.whiteLabeled {
  font-size: 12px;
  color: #fff;
  background-color: #6c68f9;
  padding: 5px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  margin-right: 8px;
}
.unAssignedByCeo {
  background-color: #f94024c7;
  padding-top: 4px;
  padding-right: 5px;
  padding-bottom: 1.5px;
  padding-left: 5px;
}
.whiteLabeled svg {
  max-width: 14px;
  max-height: 15px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: text-bottom;
}
.upgrade-now {
  font-size: 12px;
  color: #fff !important;
  width: 25px;
  height: 25px;
  position: relative;
  background: #3ca41d;
  padding: 3px;
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  -webkit-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  -moz-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  -ms-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  -o-transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.upgrade-now span {
  display: none;
}
.upgrade-now svg {
  width: 18px;
  height: 18px;
}
.table-gen tr:hover .upgrade-now span,
.upgrade-now svg {
  display: inline-block;
  vertical-align: text-bottom;
}
.table-gen tr:hover .upgrade-now span {
  margin-left: 5px;
}
.table-gen tr:hover .upgrade-now {
  width: auto;
  padding: 3px 5px;
}
.tagsTd {
  margin-top: 10px;
  font-size: 0;
}
.upgrade-now,
.whiteLabeled {
  display: inline-block;
  vertical-align: middle;
}
.repoMention svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: sub;
}
.repoMention svg * {
  fill: #9cb0bb;
}
.repoMention {
  margin-top: 10px;
  font-size: 13px;
  color: #9cb0bb;
}

.repoMention.green {
  color: rgb(103, 216, 89);
}
.repoMention.red {
  color: #f94024;
}

.table-gen .table-name:after {
  clear: both;
  content: "";
}
.table-gen-inLogo {
  float: left;
  margin-right: 10px;
}
.nameCellText {
  overflow: hidden;
}
tr.upgradeable {
  background-color: rgba(103, 216, 89, 0.04) !important;
}
tr.not-created {
  background-color: rgba(249, 64, 36, 0.04) !important;
}

/* 03-02-2021 */
.optionsDetailDashboard {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.tabHeader .searchTab .search-gen + .extensionSelect {
  margin-right: 0;
}
.optionsDetailDashboard .btn {
  width: auto;
  padding: 0 15px;
  height: 32px;
  text-transform: capitalize;
  font-size: 14px;
}
.optionsDetailDashboard .btn svg {
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.optionsDetailDashboard .managePlanBtn {
  order: 1;
}
.optionsDetailDashboard .checkAuthAPI {
  display: inline-flex;
  align-items: center;
  order: 2;
}
.optionsDetailDashboard .checkAuthAPI span {
  font-size: 13px;
  color: rgb(136, 136, 136);
  margin-left: 8px;
}
.optionsDetailDashboard .broadcast-btn {
  order: 3;
}
.optionsDetailDashboard > * {
  margin-left: 30px;
}
.switchAuthentication .react-switch-bg {
  height: 24px !important;
  width: 48px !important;
}
.switchAuthentication .react-switch-bg {
  border: 3px solid #808080;
  background: none !important;
  border-radius: 30px !important;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}
.switchAuthentication .react-switch-handle {
  background: #808080 !important;
  width: 14px !important;
  height: 14px !important;
  top: 50% !important;
  transform: translateY(-50%) translateX(5px) !important;
  -webkit-transform: translateY(-50%) translateX(5px) !important;
  -moz-transform: translateY(-50%) translateX(5px) !important;
  -ms-transform: translateY(-50%) translateX(5px) !important;
  -o-transform: translateY(-50%) translateX(5px) !important;
}
.switchAuthentication.checked .react-switch-handle {
  background: #fff !important;
  transform: translateY(-50%) translateX(28px) !important;
  -webkit-transform: translateY(-50%) translateX(28px) !important;
  -moz-transform: translateY(-50%) translateX(28px) !important;
  -ms-transform: translateY(-50%) translateX(28px) !important;
  -o-transform: translateY(-50%) translateX(28px) !important;
}
.switchAuthentication.checked .react-switch-bg {
  background: #67d859 !important;
  border-color: #40a128 !important;
}
.switchAuthentication input[type="checkbox"] {
  display: none;
}
.switchAuthentication .react-switch-bg > * {
  display: none;
}
.apiOptionsDetails {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 30px;
}
.apiOptionsDetails .btn {
  padding: 0 15px;
  width: auto;
  height: 34px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  margin: 0 6px;
}
.apiOptionsDetails .btn i.fa,
.apiOptionsDetails .btn svg {
  margin-right: 6px;
}
.apiOptionsDetails .btn:hover .chromeIcon .a,
.apiOptionsDetails .btn:hover .chromeIcon .b,
.apiOptionsDetails .btn:hover .chromeIcon .c,
.apiOptionsDetails .btn:hover .chromeIcon .d {
  fill: #ffffff;
  transition: all ease-in-out 0.4s;
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
}
.apiOptionsDetails .btn svg {
  height: 22px;
  width: 22px;
  fill: #67d859;
}
.inputLinkText {
  display: flex;
}
.inputLinkText {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}
.inputLinkText * {
  border: none;
}
.inputLinkText {
  margin: 0 6px;
}
.inputLinkText input {
  font-size: 14px;
  min-height: 34px;
}
.inputLinkText button {
  width: 34px;
  font-size: 16px;
  height: 34px;
}
.form-row .ace_editor {
  width: 100% !important;
}

.subRow {
  padding: 12px 25px;
  margin-top: 10px;
  background: #edeff0;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.02);
}
.subRow label {
  color: #333;
  font-size: 13px;
}
.subRow .row .col {
  margin-bottom: 0;
  margin-top: 10px;
}
.subRow .form-control {
  padding: 10px 12px;
  color: #333;
  font-size: 13px;
  border: 1px solid #ccc;
}
.subRow input[type="file"]::-webkit-file-upload-button {
  height: 24px;
  margin-right: 5px;
  background: #edeff0;
  border-radius: 3px;
  border: 1px solid #cecece;
  color: #b4b4b4;
}
.subRow .loaderSet {
  display: flex;
  align-items: flex-start;
}
.loaderSet figure.image-display {
  margin-top: 0;
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border: 1px solid #00aaff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  order: 1;
}
.subRow .loaderSet .form-control {
  order: 2;
  border: none;
}
/* 03-02-2021 */

/* 03-15-2021 */
.paraExt {
  max-width: 100%;
}

.repoMention span {
  margin-left: 4px;
}
.control-label .switchAuthentication {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 5px;
}
.control-label .fa {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}
.formInWhitelabel input[disabled] {
  background: #eee;
  cursor: no-drop;
}
/* 03-15-2021 */

/* 03-18-2021 */
.extInfo .whitelabeled {
  vertical-align: text-top;
  margin-left: 10px;
}

.btn-hollow-red {
  color: #e02251;
  border-color: #e02251;
}

.btn-hollow-red:hover {
  background-color: #e02251;
}

.btn-hollow-red:hover {
  color: #fff;
}
.modal-confirm .btn {
  display: inline-flex;
  width: 150px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 8px;
  width: 90px;
}
.modal-confirm .modal-gen-body {
  padding-bottom: 20px;
}

/* PLAN MULTI SELECT */
.tabHeader .searchTab .planMultiSelect {
  margin-right: 0;
  width: auto;
  min-width: 120px;
  max-width: 270px;
}
.planMultiSelect .css-1wa3eu0-placeholder {
  font-size: 13px;
}
.planMultiSelect input {
  min-height: inherit;
}
.tabHeader
  .searchTab
  .planMultiSelect
  .css-2b097c-container
  .css-yk16xz-control
  > *:last-child {
  display: none;
}
.tabHeader .searchTab .planMultiSelect .css-1pahdxg-control {
  border: none !important;
}
.tabHeader .searchTab .planMultiSelect .css-2b097c-container {
  font-size: 14px !important;
}
.tabHeader
  .searchTab
  .planMultiSelect
  .css-2b097c-container
  .css-1pahdxg-control
  :hover {
  border: none !important;
}
.planMultiSelect .css-12jo7m5 {
  font-size: 13px;
  padding-top: 5px;
}
.planMultiSelect .react-select__option {
  font-size: 14px !important;
}
.tabHeader
  .searchTab
  .planMultiSelect
  .css-2b097c-container
  .css-yk16xz-control
  > *:first-child {
  padding-right: 20px;
}
/* PLAN MULTI SELECT */

@media (min-width: 641px) {
  .modal-confirm .modal-gen-in {
    max-width: 480px;
  }
  .modal-confirm .btn {
    width: 150px;
  }
}
/* 03-18-2021 */

/* 06-29-2021 */
.kyubiDa ~ .bodyLoggedIn {
  padding-top: 0;
}
/* 06-29-2021 */

.partner-plan-select *[aria-live=polite] {
  display: none;
}
.partner-plan-select > div:first-of-type{
  position: relative;
  height: 100%;
  width: 100%;
  color: #808080;
  padding: 5px 10px;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  min-height: 60px;
  border-radius: 3px;
  font-size: 15px;
  transition: all ease 0.4s;
}

/* RESPONSIVE */

@media (min-width: 1600px) {
  .table-gen .table-name {
    min-width: 220px;
  }
}

@media (max-height: 640px) {
  .footerCommon {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
  }
}

@media (max-width: 1600px) {
  .statNumbers h3 {
    font-size: 42px;
  }
}

@media (max-width: 1199px) {
  .navbar-container {
    padding-left: 20px;
  }
  .dashboardBlocks {
    padding: 20px 100px 20px 20px;
  }
  .dashboardBlocks h5 {
    font-size: 13px;
  }
  .kyubiStatistics {
    margin-bottom: 50px;
  }
  .dashboardBlocks {
    min-height: 130px;
    padding: 15px 60px 15px 15px;
  }
  .statNumbers h3 {
    font-size: 24px;
  }
  .dashboardBlocks .linkText {
    bottom: 32px;
    font-size: 13px;
    line-height: normal;
    right: 10px;
  }
  .createBtn {
    padding-right: 15px;
    font-size: 15px;
  }
  .headerMenu a,
  .headerMenu a:not(.btn-hollow) {
    font-size: 15px;
  }
  .tabHeader li > .tabLink {
    padding: 21px 15px;
  }
  .searchTab .search-gen {
    max-width: 150px;
  }
  .searchTab .btn {
    padding: 0 15px;
  }
}

@media (max-width: 1024px) {
  .navbar-container {
    padding-left: 30px;
  }
  .headerMenu a {
    font-size: 13px;
  }
  .createBtn,
  .btn-large,
  .navbar .btn-medium {
    height: 46px;
    font-size: 13px;
  }
  .userMenu {
    width: 46px;
    height: 46px;
  }
  .bodyLoggedIn {
    padding-top: 40px;
  }
  .table-gen td,
  .table-gen th {
    min-height: 56px;
  }
  .tabHeader li > .tabLink {
    padding: 18px 25px;
    font-size: 13px;
  }
  .menu-gen .btn {
    font-size: 13px !important;
  }
  .mainInner .headerSection h2 {
    font-size: 24px;
  }
  a[data-title]:hover:after,
  .tooltip[data-title]:hover:after {
    font-size: 13px;
  }
  .nav-menuTray {
    right: 15px;
  }
}

@media (max-width: 991px) {
  .headerSection h2 {
    font-size: 24px;
  }
  .form_inner .form-control {
    font-size: 13px;
  }
  .modWidth {
    padding: 50px 0;
  }
  .headerMenu a + a {
    margin-left: 15px;
  }
  .headerMenu {
    margin-right: 15px;
  }
  .navbar-container {
    padding-left: 15px;
  }
  .search-gen .form-control {
    padding-left: 30px;
    font-size: 13px;
  }
  .search-gen .bgForm .search-ico {
    width: 15px;
  }
  .search-gen .bgForm {
    left: 8px;
  }
  .searchTab .btn {
    padding: 0 12px;
    margin-left: 10px;
    font-size: 13px;
  }
  .navbar .btn-medium {
    padding: 0 25px;
  }
  /* 09-09-2020 */
  .table-responsive {
    overflow-x: auto;
    padding-bottom: 100px;
  }
  .table-responsive table,
  .table-responsive .table {
    width: 991px;
  }
  .searchTab {
    max-width: 100%;
    width: 100%;
    order: 1;
    margin-bottom: 10px;
  }
  .tabHeader {
    flex-wrap: wrap;
  }
  .searchTab .search-gen {
    /* max-width: 100%; */
    max-width: 130px;
    margin-left: 10px;
  }
  .tabHeader ul {
    order: 2;
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
  }
  .searchTab .form-control {
    height: 100%;
    margin-top: 0;
  }
  .custom_select select {
    font-size: 14px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .kyubiStatistics .col {
    margin-top: 30px;
  }
  .kyubiStatistics .col-3 {
    max-width: 50%;
  }
}

@media (max-width: 670px) and (min-width: 641px) {
  .navbar .createBtn + .createBtn {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 15px 0;
  }
  /* .footerCommon {
        padding-left: 0;
        padding-right: 0;
    } */
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
  }
  .form_inner .form-control,
  .modal-gen-body .form-control,
  .btn-large,
  .btn-medium {
    height: 46px;
  }
  .col + .col,
  .form-group + .form-group,
  .modal-gen-body .form-group + .form-group {
    margin-top: 15px;
  }
  .modWidth {
    padding: 0;
  }
  .createBtn {
    padding: 0 25px 2px 30px;
  }
  .createBtn:hover:after {
    transform: translateX(-30px) rotate(45deg);
  }
  .createBtn:hover span {
    transform: rotate(90deg) translateY(31px);
  }
  .table-responsive table,
  .table-responsive .table {
    width: 767px;
  }
  .tab-loader .loader-gen {
    /* top: -100px; */
    top: 0;
  }
  .nav-menuTray ul {
    padding: 13px 15px;
  }
  .nav-menuTray ul span,
  .nav-menuTray ul a {
    padding: 5px 0;
    font-size: 13px;
  }
  .userSnap {
    min-height: 72px;
  }
  .logo {
    max-width: 83px;
  }
  .logo svg {
    transform: scale(0.8);
    transform-origin: 0 50%;
  }
  .apiBody {
    font-size: 13px;
  }
  .headerMenu a,
  .headerMenu a:not(.btn-hollow) {
    font-size: 13px;
  }
  .headerMenu a + a {
    margin-left: 10px;
  }
  .headerMenu {
    margin-right: 10px;
  }
  .navbar-container {
    padding-left: 0;
  }
  .createBtn {
    padding-right: 15px;
  }
  .navbar .btn-medium {
    padding: 0 15px;
  }
  .table-responsive {
    position: relative;
  }
  .tab-loader {
    height: 62px;
    position: absolute;
    left: 0;
    right: 0;
  }
}

@media (max-width: 640px) {
  .headerSection p {
    font-size: 13px;
  }
  .modal-gen-in {
    max-width: calc(100% - 30px);
  }
  .modal-headerTxt h3 {
    font-size: 22px;
  }
  .modal-headerTxt p {
    font-size: 14px;
  }
  .modal-gen-body {
    padding: 15px 15px 30px;
  }
  .modal-gen-header {
    padding: 15px 30px 15px 15px;
  }
  .form-group label {
    font-size: 14px;
  }
  .tabBody,
  .tableDesign {
    padding: 0 5px;
  }
  .tabHeader li > .tabLink {
    padding: 12px 15px;
  }
  .navbar > .container {
    flex-wrap: wrap;
  }
  .navbar-container {
    padding-left: 0;
    flex: 1 1 100%;
    width: 100%;
    margin-top: 15px;
  }
  .headerMenu a,
  .btn-large,
  .navbar .btn-medium {
    font-size: 12px;
  }
  .navbar-header {
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .createBtn {
    font-size: 0;
    padding: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    left: 15px;
    overflow: visible;
  }
  .createBtn span {
    font-size: 18px;
    left: auto;
    transform: none;
    visibility: visible;
    opacity: 1;
  }
  /* .createBtn span em {
    position: absolute;
    display: flex;
    font-size: 12px;
    background: #419af4;
    border-radius: 30px;
    height: 21px;
    justify-content: center;
    padding: 0 4px 0 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    left: 15px;
  } */
  .createBtn:hover span {
    transform: rotate(45deg);
  }
  .createBtn::after {
    opacity: 0;
    display: none;
  }
  .headerMenu a + a {
    margin-left: 10px;
  }
  .headerMenu {
    margin-right: auto;
  }
  .headerOptions {
    font-size: 0;
    position: absolute;
    top: 0;
    right: 15px;
  }
  .userMenu {
    width: 40px;
    height: 40px;
  }
  .nav-menuTray {
    right: 0;
  }
  .logo {
    max-width: inherit;
  }
  .logo svg {
    transform: scale(1);
    transform-origin: inherit;
  }
  .navbar .createBtn + .createBtn {
    left: 105px;
  }
  .titleUrl {
    display: block;
    margin: 12px 0;
    font-size: 12px;
    letter-spacing: 0;
  }
  pre {
    font-size: 11px;
  }
  .btnApi {
    font-size: 13px;
    padding: 0 20px;
  }
  .extInfo li + li {
    margin: 0 15px;
  }
  .extInfo li h6 {
    font-size: 18px;
    margin-top: 5px;
  }
  .extImg {
    width: 60px;
    height: 60px;
  }
  .extInfo h2 {
    font-size: 22px;
  }
  .paraExt p {
    line-height: 22px;
    font-size: 13px;
  }
  /* 09-09-2020 */
  .searchTab {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .searchTab .extensionSelect,
  .searchTab .btn[title],
  .searchTab .search-gen,
  .searchTab .btn {
    height: 36px;
  }
  .searchTab .search-gen {
    order: 4;
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 8px;
  }
  .searchTab .btn[title] {
    margin-left: auto;
  }
  .selectTab {
    flex: 1 1 100%;
    order: 2;
    width: 100%;
  }
  .selectTab .custom_select {
    margin: 0;
    width: 40%;
    min-width: 180px;
    margin-left: 15px;
  }
  .custom_select select {
    font-size: 13px;
    width: 100%;
  }
  .searchTab .btn {
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .mainInner .headerSection h2 {
    font-size: 22px;
  }
  .form-group label {
    font-size: 13px;
  }
  .dropFile {
    border: 2px dashed #c4c4c4;
    justify-content: center;
    border-radius: 5px;
  }
  .dropFile span,
  .inputFile span {
    font-size: 13px;
  }
  .modal-headerTxt h3 {
    font-size: 18px;
  }
  .modal-headerTxt p {
    font-size: 13px;
  }
  .userSnap .welcomePanel {
    font-size: 17px;
  }
  .btn-large,
  .navbar .btn-medium {
    height: 40px;
  }
  .userMenu {
    height: 40px;
    width: 40px;
  }
  .createBtn:hover:after {
    transform: translateX(-35px) rotate(40deg);
  }
  .nav-menuTray {
    top: 35px;
  }
  /* .navCEO .createBtn {
    height: auto;
    width: auto;
  }
  .navCEO .createBtn span {
     font-size: 16px; 
    font-size: 0;
    position: static;
  }
  .navCEO .createBtn + .createBtn{
    left: 90px;
    top: 31px;
    margin-left: 0;
  }
  .navCEO .createBtn + .createBtn,
  .navCEO .createBtn{
    left: 15px;
  }
  .createBtn span em {
    left: 7px;
    transform: none;
    position: static;
    align-items: center;
    height: 24px;
    padding: 0 10px 2px;
  } */
  /* because add was not visible below 480 */
  .createBtn span {
    font-size: 16px;
  }
  .createBtn {
    width: 30px;
    height: 30px;
  }
  .selectTab .custom_select {
    min-width: 200px;
  }
}

@media (max-width: 420px) {
  .footerCommon {
    flex-wrap: wrap;
  }
  .socialFt,
  .footer p {
    flex: 1 1 100%;
    width: 100%;
    text-align: center;
  }
  .socialFt {
    margin-top: 10px;
  }
  .headerMenu a,
  .headerMenu a:not(.btn-hollow) {
    font-size: 12px;
  }
  .navbar .btn-medium {
    padding: 0 8px;
  }
  /* 09-09-2020 */
  .searchTab .extensionSelect {
    max-width: 35%;
  }
  .searchTab .extensionSelect select {
    font-size: 12px;
  }
  .searchTab .btn,
  .searchTab .btn[title] {
    font-size: 11px;
    padding-left: 11px;
    padding-right: 11px;
  }
  .searchTab .btn {
    margin-left: 8px;
  }
}

@media (max-height: 675px) {
  .modal-gen-body {
    max-height: 60vh;
  }
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

/* 04-22-2021 */
.assignDissolveList li.selected:hover {
  background: #f56666;
}
.assignDissolveList li span {
  display: flex;
  margin-left: auto;
  margin-right: -36px;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff;
  perspective: 100px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
  -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
  transform: translateZ(0) rotateY(90deg);
  -webkit-transform: translateZ(0) rotateY(90deg);
  -moz-transform: translateZ(0) rotateY(90deg);
  -ms-transform: translateZ(0) rotateY(90deg);
  -o-transform: translateZ(0) rotateY(90deg);
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -ms-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  opacity: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: subpixel-antialiased;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABFAAAARQB+zng/wAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHMSURBVFiFtdc/b9NAHIfx5+s1ibr2BfAW4k4sid8GYoFIUYEBRAeqrh2omGgEYUPiZSTsuC8B1go2oEmkdsmPgTRqHf+5O9s32ud7PjpbtqyrYfwwMn1C1gF7051ffKHFsRwcPEJ2imm5lo20SuLvZjzYnDeTHfZmFx/aiC+S/limCSAAiR+Rrdm7M0cyTRZJf9x2HMDW7EUSJ5m5jSPy4v9DnESdeToFXuUiBvGzuvFV0n8q0/lOHI4783S6Pbgcxi+Bd5nrzYwXva/peWjcTB+BKCd+SlbVJMIlvgNoCuEazwXURfjECwGhCN94KcAXERKvBLgiQuNOgCpEFNlNaNwZUIbAAOW+ZCrjXoASRHZB57g3oArhG4fMfXMZMq42G58dtkZ/vdfzmbwaxE9MTCmGm6Hnvfm3SeMAh3gQwukWFMUFx+R9yrH3i+HBocvalTtQFr994ArfEw47UQpwid+OUEQhwCdeB5ELCImHInYAdeIhiHuAJuK+iC2gybgPQm3FXRHa/Kt9Jvs8iKPuLD2rE98ikvg1xtssAtNjLYfxT2C/rXgF4lcEXLcdB+jO0jPEUebwdQQaAZeI3zLGbcTvImSMgT/AJWj0D/cXcmuSsdhFAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0;
}
.assignDissolveList li span svg {
  width: 20px;
  height: 20px;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -ms-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.assignDissolveList li.selected span {
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  opacity: 1;
}
.assignDissolveList li:hover span {
  background-size: 40%;
}

.assignDissolveList li:hover span svg {
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -ms-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
  width: 0;
  height: 0;
}

.assignDissolveList li + li {
  border-top: 1px solid #afafaf;
  margin-top: 0;
}
.assignDissolveList li.selected + li.selected {
  border-top-color: #fff;
}
.assignDissolveList li:hover + li,
.assignDissolveList li:hover {
  border-top: none;
}

.menu-options .btn figure i.fa {
  font-size: 18px;
  color: rgb(196, 196, 196);
}
.menu-options .btn:hover figure i.fa {
  color: #67d859;
}

.menu-options .btn .updateWhitelabel {
  fill: #c4c4c4;
}
.menu-options .btn:hover .updateWhitelabel {
  fill: #67d859;
}

.react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 1) !important;
}

figure.ad-image {
  height: 90px;
  width: 728px;
}

.menu-options .btn:hover .editIcon {
  stroke: #67d859;
}

.menu-options .btn figure svg,
.menu-options .btn figure img {
  max-width: 100%;
}
.tabHeader .searchTab .addStripe {
  order: 5;
}
.menu-options .btn.deleteBtn:hover {
  color: #f94024;
}
.menu-options .btn.deleteBtn:hover svg .a {
  fill: #f94024;
}
.addStripe .btn figure {
  width: 20px;
  height: 20px;
  background: #fff;
  display: inline-block;
  vertical-align: 0;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
}
.addStripe .btn figure:after,
.addStripe .btn figure:before {
  content: "";
  width: 12px;
  position: absolute;
  height: 2px;
  background: #419af4;
}
.addStripe .btn figure:before {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
}
.addStripe .btn figure:after {
  top: 4px;
  left: 4px;
}
.addStripe .btn figure:after {
  top: 50%;
  left: 50%;
  margin-top: -1px;
  transform: translateX(-50%);
}

.addStripe {
  fill: #bbb9d1;
}

.addSpaceAutomations {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 6px #00000029;
  border-radius: 5px;
  padding-right: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  max-height: 260px;
  overflow: auto;
  transform: scale(1);
}
.addMoreAutomation {
  color: #67d859;
  background: #eaffe8 0% 0% no-repeat padding-box;
  border: 2px solid #67d859;
}

.modalAddStripedetails .modal-gen-body .form-group:not(:nth-child(1)),
.modalCreateAutomation .modal-gen-body .form-group:not(:nth-child(1)) {
  margin-top: 32px;
}

.addSpaceAutomations::-webkit-scrollbar {
  width: 9px;
}

.addSpaceAutomations::-webkit-scrollbar-track {
  background: transparent;
}

.addSpaceAutomations::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b5b5b5;
}
.addSpaceAutomations ul {
  margin-right: 12px;
}
.addMoreAutomation:hover {
  background: #67d859;
  color: #fff;
}

.addSpaceAutomations li {
  padding: 15px;
  background-color: #67d859;
  border-radius: 5px;
  color: #fff;
  align-items: center;
  /* justify-content: space-between; */
}
.addSpaceAutomations li figure {
  margin-right: 15px;
}
.addSpaceAutomations li .extAutomation {
  /* margin-right: auto; */
  flex: 0 0 150px;
  width: 100%;
  max-width: 150px;
  word-break: break-word;
  font-size: 15px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.extAutomation span {
  flex: 1 1 100%;
}
.extAutomation .form_inner {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  max-width: 130px;
  overflow: hidden;
}
.extAutomation .form_inner input {
  width: 50%;
  margin-right: auto;
  border: none;
  text-align: center;
  padding-right: 0;
  font-size: 14px;
}
.extAutomation .form_inner .switchAuthentication {
  margin-right: 8px;
}
.extAutomation .form_inner:after {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 1px;
  background: #b7b7b7;
  height: 50%;
  transform: translateY(-50%);
  margin-right: -1px;
}
.extAutomation .form_inner input:disabled {
  background: #b7b7b7;
  cursor: not-allowed;
}
.planEdit {
  background: #e1ffde;
  width: 176px;
  border-radius: 5px;
  align-self: flex-end;
  margin-right: 30px;
}
.planEdit select.form-control {
  border: none;
  height: 40px;
  font-size: 15px;
  color: #808080;
  width: 100%;
  padding: 0 35px 0 15px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0idnVlc2F4X2xpbmVhcl9lZGl0IiBkYXRhLW5hbWU9InZ1ZXNheC9saW5lYXIvZWRpdCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPGcgaWQ9ImVkaXQiPgogICAgPHBhdGggaWQ9IlZlY3RvciIgZD0iTTYuNzUsMEg1LjI1QzEuNSwwLDAsMS41LDAsNS4yNXY0LjVDMCwxMy41LDEuNSwxNSw1LjI1LDE1aDQuNUMxMy41LDE1LDE1LDEzLjUsMTUsOS43NVY4LjI1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjUgMS41KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjdkODU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8cGF0aCBpZD0iVmVjdG9yLTIiIGRhdGEtbmFtZT0iVmVjdG9yIiBkPSJNNi43NDMuOTUzLjgzMyw2Ljg2M2EyLjAzNCwyLjAzNCwwLDAsMC0uNDk1Ljk5TC4wMTYsMTAuMTExYTEuMDY4LDEuMDY4LDAsMCwwLDEuMjc1LDEuMjc1bDIuMjU3LS4zMjNhMi4xLDIuMSwwLDAsMCwuOTktLjQ5NWw1LjkxLTUuOTFjMS4wMi0xLjAyLDEuNS0yLjIsMC0zLjcwNVM3Ljc2My0uMDY3LDYuNzQzLjk1M1oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUuMjg3IDEuMzEyKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjdkODU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8cGF0aCBpZD0iVmVjdG9yLTMiIGRhdGEtbmFtZT0iVmVjdG9yIiBkPSJNMCwwQTUuMzU4LDUuMzU4LDAsMCwwLDMuNzA1LDMuNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMTgyIDMuMTEyKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjdkODU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8cGF0aCBpZD0iVmVjdG9yLTQiIGRhdGEtbmFtZT0iVmVjdG9yIiBkPSJNMCwwSDE4VjE4SDBaIiBmaWxsPSJub25lIiBvcGFjaXR5PSIwIi8+CiAgPC9nPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position: right 14px center;
}
.addSpaceAutomations li .btnDeleteAutomationExtension {
  /* margin-left: 30px; */
  margin-left: auto;
}
.planEdit select.form-control option {
  color: #000000;
}
.planEdit select.form-control option:hover,
.planEdit select.form-control option:focus,
.planEdit select.form-control option:active {
  background-color: #ebebeb;
}
.planEdit select.form-control:hover,
.planEdit select.form-control:focus {
  background-color: #fff;
  background-image: url(/static/media/down-dark.511ed015.svg);
}
.addSpaceAutomations li .extAutomation p {
  font-size: 13px;
  flex: 1 1 100%;
  margin-bottom: 3px;
}
.addSpaceAutomations li + li {
  margin-top: 10px;
}

.stripe-keys td {
  word-break: break-word;
}
.stripe-keys tbody td:last-child,
.stripe-keys thead th:last-child {
  width: 150px;
  max-width: 150px;
  text-align: center !important;
}


.stripeCast {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(10px);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.4s;
}
.stripeCast span {
  /* pointer-events: none; */
  background: #fff;
  height: 34px;
  padding: 0 30px;
  border-radius: 5px;
  font-weight: 600;
  line-height: 100%;
  color: #333;
  font-size: 13px;
  box-shadow: 0 0 2px 1px #419af4;
  display: flex;
  align-items: center;
}
.stripeCast span img {
  width: 26px;
  opacity: 0.7;
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;
}
.noShowCast {
  display: none !important;
}
.table-gen tr:hover td .stripeCast {
  background: rgb(241, 241, 241);
}
.stripeCast span:after {
  content: '';
  background-image: url(/static/media/view.3ec80ac7.gif);
  width: 36px;
  height: 36px;
  background-size: contain;
  margin-left: 4px;
  display: none;
  background-repeat: no-repeat;
  background-position: center center;
}
.stripeCast:hover span:after {
  display: inline-block;
  vertical-align: middle;
}
.stripeCast:hover span img {
  display: none;
}
.stripeCast:hover  span {
  color: #419af4;
  padding: 0 25px;
}
.addSpaceAutomations li + li:last-child .partner-plan-select > div:last-child {
  bottom: 100%;
  top: auto;
}
.partner-plan-select > div:last-child {
  font-size: 14px;
}
.manageExtension{
  width: 100%;
  margin-bottom: 30px;
}
.manageExtensionTop{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.manageExtensionTop .topBtnGroup button{
  background: #647192;
  border-radius: 5px;
  height: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  padding: 0 20px;
  border: none;
  margin: 0 5px;
  display: inline-flex;
  font-weight: normal;
  align-items: center;
}
.manageExtensionTop .addedExt{
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #647192;
}
.manageExtensionTop .addedExt span{
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #647192;
  display: inline-block;
  border-radius: 15px;
  background: #E6FFE3;
  padding: 3px 5px;
  height: 30px;
  min-width: 70px;
  margin-left: 12px;
}

.extListForPartner{
  width: 100%;
  margin: 10px 0;
  padding: 0 5px;
  overflow: auto;
  height: 392px;
}
.extListForPartner li{
  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0 0 0;
  padding: 0;
}

.extListForPartner li .state{
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}
.extListForPartner li .state:after{
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 20px);
  top: 10px;
  background-color: #F8F8F8;
  right: 0px;
}


.extListForPartner li .mode{
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  position: relative;
  border-radius: 0 5px 5px 0;
  border: none;
}
.extListForPartner li .mode:before{
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 20px);
  top: 10px;
  background-color: #f3f3f3;
  left: 0px;
}
.extListForPartner li .mode:hover{
  background: #FFE9E5;
}
.extListForPartner li .mode:hover .normal,
.extListForPartner li .mode .hover{
  display: none;
}
.extListForPartner li .mode .normal,
.extListForPartner li .mode:hover .hover{
  display: inline-block;
}
.extListForPartner li .mode:hover:before{
  display: none;
}
.extListForPartner li .balance{
  width: calc(55% - 296px);
  padding: 20px 15px;
}
.extListForPartner li .linkManager{
  width: calc(55% - 286px);
  padding: 20px 15px;
  position: relative;
}
.extListForPartner li .extDetails{
  width: 45%;
  padding: 15px;
  position: relative;
}
.extListForPartner li .extDetails:after, .extListForPartner li .linkManager:after {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 20px);
  top: 10px;
  background-color: #F8F8F8;
  right: 0px;
}

.extListForPartner li .addIt{
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transition: 0.5s;
  line-height: 36px;
  text-align: center;
  border: none;
}
.extListForPartner li .addIt svg path{
  fill:#647192;
}
.extListForPartner li .addIt:hover{
  background: #647192;
}
.extListForPartner li .addIt:hover svg path{
  fill:#fff;
}

.extDetails .extName {
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 13px;
  color: #647192;
}
.extDetails .extName .whiteTag{ 
  background: url(/static/media/whiteLb.fc334b94.svg) 0 0 no-repeat;
  width: 100px;
  height: 19px;
  padding: 0px 0 0 15px;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  display: inline-block;
  line-height: 21px;
  position: relative;
  margin-left: 9px;
}
.extDetails .extName .whiteTag:after{ 
  content: "";
  width: 3px;
  height: 3px;
  background: #fff;
  position: absolute;
  left: 6px;
  top: 8px;
}
.extDetails .extPlans {
  width: 100%;
}
.extDetails .extPlans p{
  width: 100%;
  font-size: 12px;
  line-height: 14px;
  color: #CACCD0;
}
.extDetails .extPlans p.noPlan{
  padding: 10px 0 10px 20%;
}
.extDetails .extPlans .plan{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding-top: 9px;
}
.extDetails .extPlans .plan tag{
  width: auto;
  line-height: 27px;
  height: 24px;
  border-radius: 16px;
  font-size: 14px;
  color: #FFFFFF;
  display: inline-block;
  background: #ddd;
  margin: 3px 4px 2px 0;
  padding: 0 12px;
}
.extDetails .extPlans .plan tag.gold{
  background: #FFC700;
}
.extDetails .extPlans .plan tag.bronze{
  background: #FF9B43;
}
.extDetails .extPlans .plan tag.silver{
  background: #04C000;
}
.extDetails .extPlans .plan tag.diamond{
  background: #43DCFF;
}
.extDetails .extPlans .plan tag.platinam{
  background: #B966EC;
}
/* .extDetails .extPlans .plan span{
  background: #CACCD0;
} */

button.edit {
  background: transparent;
  border: 0;
  padding: 0 10px;
  line-height: 20px;
  display: flex;
}


.selectPlan{
  width: 125px;
  height: 30px;
  background: linear-gradient(0deg, #D9ECFF 0%, #E1F0FF 0.01%, #DAECFF 22.21%, #EBF5FF 100%);
  border-radius: 4px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  border: none;
  color: #647192;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
}
.saveBtn{
  width: 30px;
  height: 30px;
  background: #419AF4;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.openPlanList{
  width: 125px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: absolute;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #647192;
  padding: 20px 18px 10px;
  z-index: 100;
  top: calc(100% + 2px);
}
.openPlanList li{
  width: 100%;
  box-shadow: none;
  margin: 0;
  padding: 0 0 14px 0;
}
.openPlanList li label{
  width: 100%;
  display: flex;
  align-content: center;
}
.customCheckbox {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin-right: 7px;
}
.customCheckbox input[type="checkbox"] {
  min-height: 13px;
  margin: 0;
  height: 13px;
  width: 13px;
  border-radius: 3px;
  position: absolute;
  z-index: 5;
  left: 0;
  top: 0;
  opacity: 0;
}
.circleRadio input[type="radio"] + span, .customCheckbox input[type="checkbox"] + span {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background: transparent;
  border: 1px solid #9baebc;
  pointer-events: none;
}
.customCheckbox input[type="checkbox"]:checked + span, 
.customCheckbox input[type="checkbox"][checked] + span {
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background:  #419AF4 url(/static/media/tick.cbe6bf21.svg) no-repeat top 2px center;
  border-color: #419AF4;
}

.balance .balanceHead {
  width: 100%;
  display: flex;
  align-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #98A2BB;
}
.linkManager .balanceHead {
  /* width: 100%; */
  display: flex;
  align-content: center;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 17px; */
  line-height: 1;
  color: #98A2BB;
}
.balance .score {
  width: 100%;
  display: flex;
  align-content: center;
  min-height: 36px;
  padding: 6px 0 0 0;
}
.balance .score span{
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #647192;

}
.balance .score input{
  width: 105px;
  height: 30px;
  min-height: 30px;
  background: #F5FAFF;
  box-shadow: inset 0px 0px 3px #a8c4e0;
  border-radius: 4px;
  border: none;
  margin-right: 2px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #647192;
}
.balance .unlimited .text{
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  color: #38A542;
}

.balance .unlimited {
    width: 100%;
    display: flex;
    align-content: center;
    padding: 6px 0 0 0;
}

.box_1{
  background: transparent;
  height: 20px;
  margin: 2px 10px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
}

input[type="checkbox"].switch_1{
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 30px;
  height: 15px;
  min-height: 15px;
  background: #CECECE;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: all .2s ease-in-out;
  margin: 0;
  min-height: 15px;
  border: none;
  }
  
  input[type="checkbox"].switch_1:checked,
  input[type="checkbox"].switch_1.active{
    background: #38A542;
  }
  
 .box_1 .toogler{
	position: absolute;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background: #B2B2B2;
	transform: scale(.7);
	left: 1px;
	top: 2px;
	transition: all .2s ease-in-out;
	border: 1px solid #fff;
	background: #fff;
  }
  
  input[type="checkbox"].switch_1:checked + .toogler,
    input[type="checkbox"].switch_1.active + .toogler{
	left: calc(100% - 14px);
  }

  .modal-gen-in-big.modify-w{
    width: 900px !important;
    max-width: 90% !important;
    border-radius: 10px;
  }
  .modal-gen-in-big.modify-w .btn.large_submit_btn {
    background: #DADADA;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;  
    pointer-events: none;
  } 
  .modal-gen-in-big.modify-w .btn.large_submit_btn.active {
    background: #2897F3;
    pointer-events: auto;
  }
  .activated{
    height: 32px;
    width: 32px;
    background: #04C000;
    border-radius: 100px;
    transition: 0.5s;
    line-height: 36px;
    text-align: center;
    border: none;
    transition: 0.5s;
  }
.activated:hover{
  background: #FF4848;
  transition: 0.5s;
}
.activated .tick,
.activated:hover .cross{
  display: inline-block;
}
.activated:hover .tick,
.activated .cross{
  display: none;
}

.extListForPartner li.inactive,
.extListForPartner li.inactive .mode{
  background: #FFF7F7;
}

.extListForPartner li.inactive .mode:hover{
  background: #E5FFE8;
}

.extDetails .extPlans .plan .planName{
  line-height: 27px;
  height: 24px;
  border-radius: 16px;
  font-size: 14px;
  color: #FFFFFF;
  display: inline-block;
  background: #CECECE;
  margin: 3px 4px 2px 0;
  padding: 0 12px;
  border: none;
  cursor: pointer;
  position: relative;
  transform: 0.5s;
}
.extDetails .extPlans .plan .planName:hover{
  background: #04C000;
  transform: 0.5s;
}

.extDetails .extPlans .plan .planName.active{
  background: #0085FF;
  transform: 0.5s;
}
.extDetails .extPlans .plan .planName.active:hover{
  background: #FF4848;
}
.extDetails .extPlans .plan .planName:hover::after{
  content: "";
  background: url(/static/media/cross1.ccfb916a.svg) center center;
  width: 100%;
  height: 100%;
}
.extDetails .extPlans .plan .planName:hover::after{
  content: "";
  background: #04C000 url(/static/media/tick1.09897509.svg) center center no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 16px;
}
.extDetails .extPlans .plan .planName.active:hover::after{
  background: #FF4848 url(/static/media/cross1.ccfb916a.svg) center center no-repeat;
}

/* 
.extDetails .extPlans .plan .planName:hover span,
.extDetails .extPlans .plan .planName.active:hover span{ 
  display: none;
}
.extDetails .extPlans .plan .planName img,
.extDetails .extPlans .plan .planName.active img{
  display: none;
}
.extDetails .extPlans .plan .planName:hover img,
.extDetails .extPlans .plan .planName.active:hover img{
  display: inline-block;
  transform: translateY(3px);
}

.extDetails .extPlans .plan .planName span,
.extDetails .extPlans .plan .planName.active span{
  display: inline-block;
} 
.extDetails .extPlans .plan .planName.active:hover img.tick,
.extDetails .extPlans .plan .planName:hover img.cross{
  display: none;
}
.extDetails .extPlans .plan .planName.active:hover img.cross,
.extDetails .extPlans .plan .planName:hover img.tick{
  display: inline-block;
}
*/

.modal-gen-body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.modal-gen-body::-webkit-scrollbar-track {
  background-color: #dce4eb;
}

.modal-gen-body::-webkit-scrollbar-thumb {
  background-color: #67d859;
}

.manageExtensionTop .topBtnGroup button.assginAll{
  background: #647192;
  }
  .manageExtensionTop .topBtnGroup button.assginAll.active{
  background: #CACACA;
  }
  .manageExtensionTop .topBtnGroup button{
  padding: 0 14px;
  }
  .assignAllcontent{
  width: 100%;
  min-height: 100px;
  background: #FFFFFF;
  box-shadow: 0px -2px 15px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  }
  .assignAllcontent:before{
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8.5px 16px 8.5px;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  top: -15px;
  left: calc(30% - 8px);
  }
  .assignAllcontent.manageExtlink:before{
    left: calc(65% - 5px);
    }
  
/* ..................................................
work for add partner 
................................................... */

.modal-gen-in.addPartner {
  max-width: 1000px;
  margin: 10px;
  width: calc(100% - 22px);
  border-radius: 10px;
}
.modal-gen-in.addPartner .modal-gen-body {
  max-height: 725px;
}
.partner_add_form{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
}
.partner_add_form .half{
  width: calc(50% - 10px);
}
.partner_add_form .half label,
.partner_add_form .half input{
  width: 100%;
  font-size: 14px;
}
.partner_add_form .half label{
  color: #647192;
  padding: 0 0 8px 0;
  display: inline-block;
}
.partner_add_form .half input{
  color: #647192;
  margin: 0 0 20px 0;
  display: inline-block;
  height: 40px;
  background: #FBFBFB;
  border: 1px solid #C8CFE0;
  border-radius: 4px;
}
.partner_add_form .half input::-webkit-input-placeholder{
  color: #AEB9D2;
}
.partner_add_form .half input:-ms-input-placeholder{
  color: #AEB9D2;
}
.partner_add_form .half input::placeholder{
  color: #AEB9D2;
}
@media (max-width: 767px) {
  .partner_add_form .half {
    width: 100%;
  }
}

.placeExtDeatils{
  width: 100%;
  background: #F3F5FA;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  overflow: auto;
  margin-bottom: 20px;
  height: 350px;
  position: relative;
}

.tagSpan{
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #647192;
  display: inline-block;
  border-radius: 15px;
  background: #E6FFE3;
  border: 1px solid #CFF4CB;
  padding: 3px 5px;
  height: 30px;
  min-width: 50px;
  margin-left: 12px;
}
.headerExtTop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.headerExtTop .name,
.headerExtTop .action{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.headerExtTop .name h3{
  font-weight: bold;
  font-size: 18px;
  color: #647192;
  padding: 0;
  margin: 0;
}
.headerExtTop .action .dropAssignBtn{
  height: 40px;
  background:#647192;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  text-shadow: none;
  padding: 0 15px;
  margin-right: 12px;
  cursor: pointer;
}
.headerExtTop .action .dropAssignBtn.active{
  background: #C8CFE0;
}
.headerExtTop .action input{
  height: 40px;
  background: #FBFBFB url(/static/media/search2.81977fab.svg) 11px 13px no-repeat;
  border: 1px solid #C8CFE0;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 10px 0 32px;
  min-height: 30px;
  font-size: 14px;
  color: #AEB9D2;
  width: 270px;
  max-width: 100%;
}

.addPartnerTopPart{
  max-height: 600px;
  /* overflow: auto; */
  margin: 0 0 20px 0;
  padding-right: 4px;
}


/* .............. reapeat/.................................. */

.assignAllcontent{
    width: 100%;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0px -2px 15px 7px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px 20px;
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .assignAllcontent:before{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8.5px 16px 8.5px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -15px;
    left: calc(50% - 33px);
  }
  
  .assignAllcontent .formflat,
  .assignAllcontent .formflat .formflatInner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .assignAllcontent .formflat .formflatInner {
    max-width: 33%;
    padding-right: 15px;
  }
  .assignAllcontent .formflat .formflatInner label:not(.box_1){
    width: 100%;
  }
  .assignAllcontent .formflat .formflatInner label{
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #647192;
    padding: 0 0 8px;
  }
  
  .assignAllcontent .formflat .formflatInner .slickForm{
    height: 30px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #CECECE;
    box-sizing: border-box;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 10px;
    min-height: 30px;
    font-size: 13px;
    color: #647192;
  }
  .assignAllcontent .formflat .formflatInner .slickForm select{
    width: 100%;
    font-size: 13px;
    color: #647192;
    border: none;
    background: transparent url(/static/media/downArrow.ec5f77e4.svg) 98% 12px no-repeat;
    min-height: 30px;
    padding: 0;
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
  }
  .assignAllcontent .formflat .formflatInner .slickForm::-webkit-input-placeholder{
    font-family: inherit;
    color: #647192;
  }
  .assignAllcontent .formflat .formflatInner .slickForm:-ms-input-placeholder{
    font-family: inherit;
    color: #647192;
  }
  .assignAllcontent .formflat .formflatInner .slickForm::placeholder{
    font-family: inherit;
    color: #647192;
  }
  .assignAllcontent .formflat .text{
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: #38A542;
  }
  .assignAllcontent .formflat .link{
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: #98A2BB;
  }
  .assignAllcontent .formflat .box_1 .toogler {
    top: 1px;
  }
  .assignAllcontent .formBtn{
    padding: 20px 0 20px 20px;
    border-left: 1px solid #f8f8f8;
  }
  .blueBtn{
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    background: #0084FF;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    text-shadow: none;
    border: none;
    width: 70px;
  }

/* .............. reapeat ends/.................................. */

.placeExtDeatils .assignAllcontent:before{
  left: calc(50% - 3px);
}
.placeExtDeatils .assignAllcontent{
  margin-top: 20px;
  min-height: 90px;
}


.btn.large_submit_btn {
  background: #DADADA;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  pointer-events: none;
} 
.btn.large_submit_btn.active {
  background: #2897F3;
  pointer-events: auto;
}
.placeExtDeatils .extListForPartner::-webkit-scrollbar,
.placeExtDeatils::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.placeExtDeatils .extListForPartner::-webkit-scrollbar-track,
.placeExtDeatils::-webkit-scrollbar-track {
  background-color: #dce4eb;
}

.placeExtDeatils .extListForPartner::-webkit-scrollbar-thumb,
.placeExtDeatils::-webkit-scrollbar-thumb  {
  background-color: #67d859;
}

.addPartnerTopPart .extListForPartner li .balance,
.addPartnerTopPart .extListForPartner li .extDetails{
  padding: 11px 15px;
}
.placeExtDeatils .extListForPartner {
  width: 100%;
  margin: 10px 0 0;
  padding: 0 8px 12px 8px;
  overflow-y: auto;
  height: 250px;
  position: relative;
}
.addPartnerTopPart .extListForPartner li .balance{  
  width: calc(50% - 246px);
  padding: 11px 15px 11px 30px;
}
.addPartnerTopPart .extListForPartner li .linkManager{
  width: calc(50% - 257px);
  padding: 11px 15px 11px 30px;
}
@media (max-width: 575px){
  .extListForPartner li .balance {
    width: calc(100% - 50px);
    border-top: 1px solid #f3f3f3;
  }
  .extListForPartner li .linkManager {
    width: calc(100% - 50px);
    border-top: 1px solid #f3f3f3;
  }
  .extListForPartner li .extDetails {
    width: calc(100% - 65px);
    border-bottom: 1px solid #f3f3f3;
  }
  .headerExtTop .action .dropAssignBtn{
    margin: 10px;
  }
}

.showList {
  display: none !important;
}

.placeExtDeatils .addOverlay{
    background: rgba(243, 245, 250, 0.7);
    top: 189px;
    bottom: 0;
    left: 3px;
    right: 3px;
    position: absolute;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    z-index: 2;
    display: block;
    cursor: not-allowed;
    height: 135px;
}
.placeExtDeatils .extListForPartner.noscroll{
    overflow: hidden;
    display: flex;
    height: 135px;
    flex-wrap: wrap;
}

.manageExtension .addOverlay{
  background: rgba(243, 245, 250, 0.7);
  top: 269px;
  bottom: 0;
  left: 0;
  right: 0px;
  position: absolute;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 2;
  display: block;
  cursor: not-allowed;
  height: 450px;
}

.searchPanel{
  padding: 12px 0 0;
}
.searchPanel .search{
  height: 40px;
  width: 100%;
  background: #FBFBFB url(/static/media/search.81977fab.svg) 11px 13px no-repeat;
  border: 1px solid #C8CFE0;
  border-radius: 4px;
  line-height: 30px;
  padding: 0 10px 0 32px;
  min-height: 30px;
  font-size: 14px;
  color: #AEB9D2;
}

.extListForPartner::-webkit-scrollbar {
  width: 6px;
}
.extListForPartner::-webkit-scrollbar-track  {
  background-color: #dce4eb;
}
.extListForPartner::-webkit-scrollbar-thumb  {
  background-color: #f1a668;
}

.partner_add_form .half .input_with_value {
  color: #647192;
  display: inline-block;
  height: 40px;
  border-radius: 4px;
  margin: 6px 0 20px 0;
  background: #F3F5FA;
  border: 1px solid #E7ECFA;
  font-size: 14px;
  line-height: 40px;
  padding: 0 14px;
  width: 100%;
}
.partner_add_form .half .input_with_value span{
  font-size: 18px;
  font-weight: 700;
}

.extDetails .existingPlan{
  color: #647192;
  font-size: 13px;
  padding: 0 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 82%;
}
.extDetails .existingPlan span{
  font-weight: 700;
}
.addPartner .balance .unlimited {
  padding: 26px 0 0 0;
}

.manageExtensionTop .topBtnGroup .suspendButton{
  background: #FF8C00;
}

.manageExtensionTop .topBtnGroup .unSuspendButton{
  background: #1E90FF;
}

/* ................................................................
api pages css
................................................................ */

.api .mainInner {
  width: calc(100% - 300px);
  padding: 20px 80px 0;
  border-top: 1px solid #dedede;
}
.api_side_pan {
  width: 300px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  background: #ffffff;
  padding: 30px 40px;
  border-top: 1px solid #dedede;
}
.api .container {
  display: flex;
  max-width: 100%;
  padding: 0;
}
.api_headding{
  border-bottom: 1px solid #CECECE;
  padding: 0 0 26px 0;
}
.api_headding h3{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #647192;
  padding: 0 0 20px;
}

.api_headding p{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #C4C9D6;
}

.api_side_pan ul {
  width: 100%;
  padding: 34px 0 0 0;
}
.api_side_pan ul li{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #C4C9D6;
  margin-bottom: 25px;
  width: 100%;
  cursor: pointer;
}

.api_side_pan ul li.active{
  color: #647192;
}
.api .tabBody {
  background: transparent;
  border: none;
  padding: 0;
}
.api_head_content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 0 30px;
  border-bottom: 1px solid #CECECE;
  margin-bottom: 20px;
}

.Webhook{
  max-width: 80%;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.Webhook .name{
  width: 94px;
  height: 40px;
  background: #4C5875;
  border-radius: 4px 0px 0px 4px;
  line-height: 40px;
  padding: 0 15px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
}
.Webhook .code{
  height: 40px;
  line-height: 40px;
  background: #fff;
  border:  1px solid #DBDEE6;
  border-radius: 0 4px 4px 0;
  padding: 0 15px;
  width: calc(100% - 189px);
  white-space: nowrap;
  overflow: hidden;
}
.Webhook .code mark {
  width: 100%;
  display: inline-block;
  padding: 0;
  height: 36px;
  background: transparent;
  font-size: 14px;
  color: #848FAA;
}

.Webhook button{
  width: 90px;
  height: 40px;
  background: #419AF4;
  border-radius: 5px;
  border: none;
  padding: 0 22px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-left: 4px;
}
.Webhook button img{
  padding-right: 5px;
}
.api_head_content h3{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #273352;
}

.apicontentbody{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0;
}
.apicontentbody .formSection,
.apicontentbody .codeSection {
  width: 50%;
}
.apicontentbody .codeSection {
  padding-left: 40px;
}
.formSection .half {
  padding: 0 15px;
  width: 50%;
}
.formSection .half label{
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #647192;
}
.formSection .half .must{
  font-size: 16px;
  line-height: 9px;
  color: red;
  padding: 3px;
}
.formSection .half tag{
  width: 50px;
  height: 18px;
  line-height: 21px;
  background: #00D5A2;
  border-radius: 46px;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  margin-left: 6px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
.formSection .half p{
  font-size: 12px;
  line-height: 14px;
  color: #B4B9C9;
  margin: 4px 0 5px;
  font-weight: 300;
}
.formSection .half input:not([type="checkbox"]){
  background: #FFFFFF;
  border: 1px solid #DBDEE7;
  border-radius: 5px;
  padding: 0 15px;
  height: 42px;
  margin: 5px 0 22px ;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4C5875;
  position: relative;
}
.formSection .half .select{
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4C5875;
  position: relative;
}
.formSection .half input::-webkit-input-placeholder{
  color: #D8DDE9;
}
.formSection .half input:-ms-input-placeholder{
  color: #D8DDE9;
}
.formSection .half input::placeholder{
  color: #D8DDE9;
}

.formSection .half .label.hadAction {
    display: flex;
    justify-content: space-between;
}
.formSection .half .label.hadAction .toggle{
  display: flex;
  padding-top: 6px;
}
.formSection .half .label.hadAction .text{
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #848FAA;
}
.formSection .half .label.hadAction .toggle .box_1{
  margin: 0 5px;
}

.formSection .half .label.hadAction .toggle .box_1 .toogler {
  top: 2px;
}

.formSection .half .select{
  margin-top: 9px;
  padding: 0;
}
.formSection .half input:not([type="checkbox"]).selected, .formSection .half .select.selected{
  background: #F9FFF9;
  border: 1px solid #BFD9C2;
  color: #38A542;
  font-weight: 700;
}
.formSection .half .select.selected{
  height: 42px;
  padding: 0px 20px;
  line-height: 42px;
  border-radius: 6px;
}
.formSection .half .select select{
  width: 100%;
  border: none;
  background: transparent;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 17px;
  color: #4C5875;
  line-height: 40px;
  padding: 0;
}
/* .formSection .half .select:after {
  content: "";
  position: absolute;
  right: 18px;
  width: 20px;
  top: 15px;
  height: 20px;
  background: transparent url(/static/media/downArrow2.b7bde52d.svg) 0 0 no-repeat;
  right: 10px;
  pointer-events: none;
} */

.codeSection .showCode{
  background: #F7FAFC;
  border: 1px solid #647192;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 30px;
}
.codeSection .showCode .headpart{ 
  background: #E3E8ED;
  border-radius: 9px 9px 0px 0px;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.codeSection .showCode .headpart h3{ 
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4C5875;
}

.codeSection .showCode .headpart .type{
  color: #848FAA;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.codeSection .showCode .headpart button{
  background-color: transparent;
  border: none;
  line-height: 10px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-left: 10px;
}
.codeSection .showCode .headpart button:hover{
  background-color: #848FAA     ;
}
.codeSection .showCode .headpart button path{
  fill:#4C5875;
}
.codeSection .showCode .headpart button:hover path{
  fill:#fff;
}
.codeBody {
  background: transparent;
  max-height: 300px;
  width: calc(100% - 20px);
  padding: 0px 30px;
  overflow: auto;
  margin: 10px;
  display: flex;
  line-height: 29px;
  font-weight: 500;
  font-size: 14px;
  color: #4C5875;
  align-items: flex-start;
  text-align: left;
  white-space: pre; 
  word-spacing: normal; 
  word-break: normal; 
  overflow-wrap: normal; 
  -moz-tab-size: 4; 
       tab-size: 4; 
  -webkit-hyphens: none; 
      -ms-hyphens: none; 
          hyphens: none;

}
.codeBody::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.codeBody::-webkit-scrollbar-track {
  background: transparent;
}

.codeBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #4C5875;
}
.codeSection .showCode.staticCode {
  background: #848FAA;
 }
.codeSection .showCode.staticCode .headpart{
 background: #4C5875;
}
.codeSection .showCode.staticCode .headpart h3,
.codeSection .showCode.staticCode .codeBody{
  color: #fff;
}
.codeSection .showCode.staticCode .headpart .type{
  color: #848FAA;
}

.formSection .half .select input {
  height: auto;
  min-height: auto;
  margin: 0;
}

.css-yk16xz-control {
  background-color: #fff !important;
  border-color: #DBDEE7!important;
  border-radius: 5px!important;
  min-height: 42px!important;
}
.css-yk16xz-control:hover {
  border-color: #DBDEE7!important;
}
.css-11aao21-a11yText,
.css-11aao21-a11yText:hover{
  border-color: transparent!important;
}

.css-1pahdxg-control,
.css-2b097c-container,
.css-1pahdxg-control:hover{
  border-color: transparent!important;
}
.css-1wa3eu0-placeholder{
  font-size: 14px;
  line-height: 17px;
  color: #D8DDE9 !important;
  font-weight: 500;
}
.css-1okebmr-indicatorSeparator{
  background-color: transparent !important;
}
.css-1uccc91-singleValue {
  color: #4C5875 !important;
}

.css-1hb7zxy-IndicatorsContainer div:first-child {
  padding: 2px;
  background: #B4B9C9;
}

.css-1rhbuit-multiValue {
  background-color: #647192 !important;
  border-radius: 16px !important;
  padding: 2px;
  color: #fff;
}
.css-12jo7m5 {
  color: #fff !important;
  line-height: 20px !important;
  padding: 0 2px 3px 1px;
}
.css-xb97g8 {
  border-radius: 50px !important;
}
.css-xb97g8:hover {
  background-color: rgb(0,0,0,0.2) !important;
  color: #fff !important;
}
.styleOption{
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
}
.css-2b097c-container *, .css-2b097c-container *:hover {
  border-width: 1px !important;
  box-shadow: none;
  height: auto;
}
.css-14el2xx-placeholder {
  margin-top: 3px;
}

.css-1s2u09g-control,
.css-108alf5-control {
  border-color: #DBDEE7 !important;
  min-height: 42px !important;
  border-width: 1px !important;
}

.formSection .half .label.hadAction ~ .select {
  margin-top: 6px;
}

.api .bodyLoggedIn {
  padding-top: 0px;
}


.formSection .half .label.hadAction.gap1 {
  padding-top: 12px;
}

.footerCommon.api{
  background: #fff;
  display: flex;
  justify-content: center;
  max-width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.api .bodyLoggedIn, .api .bodyLoggedIn > .container {
  padding-top: 0px;
  min-height: 100vh;
}

@media (max-width: 1440px){

  .formSection .half .label.hadAction {
    display: inline-block;
  }
  .formSection .half .label.hadAction > div {
    width: 100%;
  }
}
@media (max-width: 1200px){
  .apicontentbody .formSection, .apicontentbody .codeSection {
    width: 100%;
    padding: 20px 20px 0;
  }
  .api_head_content h3{
    width: 100%;
  }
  .Webhook {
      max-width: 100%;
      margin-top: 10px;
  }
}

.side_right{
  justify-content: flex-end;
  display: flex;
}
.posttype{
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 60px;
}
.posttype span{
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #4C5875;
}

.postBtnText{
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #DBDEE6;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  color: #848FAA;
  margin-left: 10px;
  padding: 0 14px;
  line-height: 40px;
}





.extListForPartner li .linkManager {
  display: flex;
  grid-gap: 0;
  gap: 0;
  align-items: center;
  justify-content: center;
}
.extListForPartner li .linkManager .unlimited {
  display: flex;
}
.extListForPartner li .linkManager .box_1 {
  margin-top: 0;
  margin-bottom: 0;
}

/* Link Manager Css 12.04.2022 */

.tabBody.listManagements {
  border-top: none;
    border-radius: 0;
}
.ListManagerLinksWraper {
  width: 100%;
  background: #f4f4f4;
  padding: 24px 43px;
}



.switchBtn {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 25px;
}

.switchBtn input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderBtn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.sliderBtn:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 5px;
  bottom: 5px;
  background-color: white;
  transition: .4s;
}

input:checked + .sliderBtn {
  background-color: #67D859;
}

input:focus + .sliderBtn {
  box-shadow: 0 0 1px #67D859;
}

input:checked + .sliderBtn:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderBtn.round {
  border-radius: 34px;
}

.sliderBtn.round:before {
  border-radius: 50%;
}
.formRow {
  max-width: 100%;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.formRow  label {
  font-size: 15px;
  color: #808080;
  display: block;
  line-height: 31px;
}

.searchTab .formRow .form-control.linksInputs {
  width: 100%;
  min-height: 40px;
  font-size: 15px;
  padding: 12px 70px 12px 8px;
  vertical-align: middle;
  background-color: #FBFBFB;
  border: 1px solid #C8CFE0!important;
  border-radius: 4px;
}

.formRow:last-child {
  margin-bottom: 0;
}

.editsWraper {
  position: absolute;
  top: 50px;
  right: 15px;
}

.editsWraper span {
  cursor: pointer;
}

.editLinks {
  margin-right: 17px;
}
.saveLinks {
  background: #0084FF;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  box-shadow: none;
  padding: 13px 23px;
  border-radius: 4px;
  line-height: 16px;
  margin: 35px 17px 0 17px;
}
.InputWraper {
  position: relative;
  width: 100%;
}

.switchBtnText {
  color: #B3B3B3;
  font-weight: 400;
  font-size: 14px;
  width: 180px;
  margin-top: 35px;
}

.formRow .switchBtn {
  margin-top: 30px;
  width: 67px;
}

.searchTab.linkTabs {
  padding: 0;
  border-bottom: none;
}

.addRow {
  width: 100%;
  background: #F4F4F4;
  padding: 8px 8px;
  border: 1px solid #dddddd;
  border-bottom: 0;
  height: 58px;
}
.saveLinks.addLinks {
  height: 40px;
  margin: 0;
  float: right;
  padding: 12px 14px;
  display: flex;
  font-weight: 400;
}

.saveLinks.addLinks figure {
  margin-right: 7px;
}

.saveLinks.addLinks figure img {
  vertical-align: top;
}

.modal-gen-in.linkModal {
  max-width: 802px;
  border-radius: 10px;
}

.form_inner .linksInput {
  height: 40px;
}

.modal-gen-body .form-group.linksModal + .form-group.linksModal {
  margin-top: 10px;
}

.form-control.linksInput:disabled {
  background: #F3F5FA;
}

.form-group.linksModal.row .btn.btn-blue.btn-large{
background:#0084FF;
font-weight: 700;
height: 40px;
font-size: 16px;
}

.form-group.linksModal.row .btn.btn-blue.btn-large figure {
  margin-right: 5px;
}

.form-group.linksModal.row .btn.btn-blue.btn-large figure img {
  vertical-align: middle;
  width: 16px;
}

.listManagement .editsBtn {
  border-bottom: 1px solid #F2F2F2;
}

.listManagement .btn + .btn {
  margin-top: 5px;
}

.listManagement .menu-gen {
  min-width: 115px;
}

.linksModal .form_inner .switchBtn {
  margin-top: 15px;
}


td .switchBtn {
  height: 20px;
  width: 40px;
}

td .sliderBtn:before {
  height: 12px;
  width: 12px;
  left: 5px;
  bottom: 4px;
}
td input:checked + .sliderBtn:before {
  transform: translateX(18px);
}
.modal-gen-in.linkModal .modal-gen-header{
  padding: 20px 40px 17px;
}

.modal-gen-in.linkModal  .modal-gen-body {
  padding: 14px 40px 38px;
}

.linksModal  .form_inner .form-control{
  background: #FBFBFB;
  border: 1px solid #C8CFE0;
  border-radius: 4px;
}

.linksModal  .form_inner .form-control.linksInput:disabled {
  background: #F3F5FA;
}
.searchTab .formRow .form-control.linksInputs:disabled {
  background: #F3F5FA;
}
.col.val{
  width: 14%;
  padding: 0 0 0 15px;
}
.col.valInput{
  width: 86%;
  padding: 0 15px 0 0px;
}
.tabHeader .searchTab.linkManager .search-gen {
  order: 0;
}
.optionsDetailDashboard .broadcast-btn{
  z-index: 1;
}

.saveLinks.addLinks.active{
  background: #8C9FB1;
}
.listManagement .editsBtn:last-child {
  border: none;
}
button.btn.btn-medium.editsBtn.partnerAdd {
  justify-content: flex-start;
}

.table-gen td span.on {
  color: green;
  font-weight: bold;
}

.table-gen td span.off {
  color: red;
  font-weight: bold;
}

.table-gen td.text-center{
  text-align: center !important;
  font-weight: bold;
  font-size: large;
}
.ListManagerLinksWraper .saveLinks {
  font-weight: 400;
}

.table-gen td.customPointer { word-break: break-all; max-width: 50%; }

.notAllowed {
  cursor: not-allowed !important;
}

.warnMessage{
  text-align: center;
  font-size: 12px;
  width: 100%;
  display: flex;
  position: absolute;
  left: 8px;
  bottom: 5px;
  color: #b77a0a;
  font-weight: 700;
}

.table-gen tr {
  position: relative;
}

*:not(button):not(input)[disabled] {
  pointer-events: none;
}
.react-confirm-alert .custom-ui {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #666;
}

.react-confirm-alert .custom-ui footer {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert .custom-ui footer button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.react-confirm-alert p {
  word-break: break-word;
}
/* form */
.leftInner {
    max-width: 730px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leftLog {
    position: relative;
    z-index: 3;
}

.rightLog {
    background-color: #68DA62;
    position: relative;
    z-index: 2;
}

.logFig {
    position: relative;
    z-index: 9;
}

.chromeExtNotif {
    width: 175px;
    height: 175px;
    background: rgba(249, 229, 34, 0.66);
    border-radius: 50%;
    position: absolute;
    top: -20px;
    right: -55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chromeExtNotif .add {
    width: 27px;
    height: 27px;
    background: #F94024 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #00000062;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.chromeExtNotif .add svg {
    margin-left: 1px;
    margin-top: 1px;
}

.chromeExtNotif .outerAdd {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    border-radius: 50%;
    border: 10px solid rgba(65, 154, 244, 0.70);
    background: rgba(249, 229, 34, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: grow 2s linear 2s infinite forwards;
}

@keyframes grow {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    60% {
        transform: scale(1);
        opacity: 1;
    }

    90% {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

.logFig svg .a {
    fill: #fff;
}

.logC {
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
}

.logC-1 {
    width: 115.5px;
    height: 231px;
    top: 136px;
    z-index: -1;
    border-radius: 0;
    border-bottom-left-radius: calc(231px * 2);
    border-top-left-radius: calc(231px * 2);
    right: 100%;
}

.logC-2 {
    width: 183.5px;
    height: 367px;
    top: 0;
    z-index: -1;
    border-radius: 0;
    border-bottom-right-radius: calc(183.5px * 2);
    border-top-right-radius: calc(183.5px * 2);
    left: 0;
}

.logC-3 {
    width: 102px;
    height: 102px;
    right: 157px;
    bottom: 122px;
}

.logC-4 {
    width: 320px;
    height: 320px;
    right: -90px;
    bottom: -60px;
}

.logC-5 {
    width: 90px;
    height: 180px;
    z-index: -1;
    border-radius: 0;
    border-bottom-left-radius: calc(90px * 2);
    border-top-left-radius: calc(90px * 2);
    right: 0;
    bottom: 189px;
    z-index: 2;
}

.headerLog {
    margin-bottom: auto;
    padding: 39px 0 0;
}

.footerLog {
    position: relative;
    left: auto;
    margin-bottom: 15px;
    padding: 0;
    margin-top: auto;
    padding-right: 70px;
}

/* RESPONSIVE */
@media (max-width: 1600px) {
    .leftLog {
        padding: 0 15px;
    }
}

@media (max-width: 1335px) {
    .headerLog {
        padding: 20px 0 0;
    }

    .logForm {
        width: 100%;
        max-width: 100%;
    }

    .footerLog {
        padding-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1335px) {
    .logForm {
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
}

@media (max-width: 1199px) {
    .logFig {
        transform: scale(0.7);
    }
}

@media (max-width: 991px) {

    .logC-1,
    .logC-2 {
        display: none;
    }

    .headerSection {
        margin-bottom: 20px;
    }

    .logForm {
        max-width: 100%;
    }

    .forgotPass p,
    .footer p {
        font-size: 13px;
    }

    .logC-4 {
        width: 230px;
        height: 230px;
    }

    .logC-3 {
        width: 72px;
        height: 72px;
        right: 107px;
        bottom: 92px;
    }

    .logC-5 {
        width: 70px;
        height: 140px;
        bottom: 129px;
    }
}

@media (max-width: 767px) {
    .headerLog {
        position: fixed;
        top: 0;
        left: 15px;
        right: 15px;
        margin: 0;
    }

    .logScreen {
        flex-direction: column;
        justify-content: center;
    }

    .leftLog,
    .rightLog {
        width: 100%;
    }

    .leftLog {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logFig {
        display: none;
    }

    .leftInner {
        display: block;
        margin: 0;
        max-width: 100%;
    }

    .headerLog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: #F8F8F8;
        z-index: 2;
    }

    .footerLog {
        position: fixed;
        width: auto;
        right: 0;
        left: 0;
        padding: 0 15px;
    }

    .logC {
        position: fixed;
    }

    .logForm {
        margin-top: auto;
    }
}

@media (max-height: 600px) {
    .headerLog {
        margin-bottom: 30px;
    }

    .headerSection {
        margin-bottom: 15px;
    }

    .form-group+.form-group {
        margin-top: 15px;
    }

    .logForm {
        margin-bottom: 30px;
    }

    .logFig {
        transform: scale(0.7);
    }
}

@media (max-height: 600px) and (max-width: 767px) {
    .logScreen {
        padding-top: 95px;
    }

    .footerLog {
        position: static;
        margin-top: 60px;
        padding: 0;
    }

}

/* RESPONSIVE */
.big-loader-gen svg {
  top: 20px;
  margin-top: -130px;
  transform: scale(15);
  display: inline-block;
}
.big-loader-gen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.suspense-load-text {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 30px;
  color: #c4c4c4;
  padding-top: 58vh;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.suspense-load-text span {
  z-index: 999;
  font-size: 30px;
  position: relative;
  color: #c4c4c4;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

