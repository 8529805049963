.big-loader-gen svg {
  top: 20px;
  margin-top: -130px;
  -webkit-transform: scale(15);
  -ms-transform: scale(15);
  transform: scale(15);
  display: inline-block;
}
.big-loader-gen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 999;
}
.suspense-load-text {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 30px;
  color: #c4c4c4;
  padding-top: 58vh;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.suspense-load-text span {
  z-index: 999;
  font-size: 30px;
  position: relative;
  color: #c4c4c4;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
